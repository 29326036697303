'use strict';
import {useAuthStore} from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import axios from 'axios';
import { useVehiclesStore } from '../stores/vehicles';
import { useSystemStore } from '../stores/system';
import { watch, ref } from 'vue';

const addVehicle = async (data)=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }

        const requestBody = data;
        return axios.get(apiEndpoint + '/dealer/' + dealerStore.getDealerData().id + "/tools/vin-decode/" + vin, requestBody, configs)
        .then(response => response.data)
        .catch((error) => {

        })
    }
}

const editVehicle = async (file, dealer, data)=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        return axios.get(apiEndpoint + '/dealer/' + dealerStore.getDealerData().id + "/tools/vin-decode/" + vin,configs)
        .then(response => response.data)
        .catch((error) => {

        })
    }
}
// const getVehicle = async (vehicle_id)=> {
//     const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

//     const authStore = useAuthStore();
//     const dealerStore = useDealerStore();
//     if (authStore.authStatus && dealerStore.getDealerData().id) {
//         const configs = {
//             headers: {
//                 "x-auth-token" : authStore.getAuthToken()
//             }
//         }
//         return await axios.get(apiEndpoint + '/' + dealerStore.getDealerData().id + "/vehicles/" + vehicle_id,configs)
//         .then(response => response)
//         .catch(error => error.response)
//     }
// }


const getVehicle = async (vehicle_id) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    console.log('haha')
    // Return a Promise that resolves when both authStatus and dealerData.id are valid
    return new Promise((resolve, reject) => {
        // Watch for changes in both authStatus and dealerData.id
        const stopWatch = watch(
        () => [authStore.authStatus, dealerStore.getDealerData().id], // Watch both values
        async ([newAuthStatus, newDealerId]) => {
            // Proceed only if both authStatus is true and dealer id is available
            if (newAuthStatus && newDealerId) {
            try {
                const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken(),
                },
                };

                // Await the axios response
                const response = await axios.get(
                `${apiEndpoint}/${newDealerId}/vehicles/${vehicle_id}`,
                configs
                );

                stopWatch(); // Stop watching after we have a successful response
                resolve(response); // Resolve the Promise with the response data
            } catch (error) {
                console.error("Error fetching vehicle data:", error);
                stopWatch(); // Stop watching if there's an error
                reject(error.response || { status: 500, data: "Internal Server Error" }); // Reject the Promise with the error
            }
            }
        },
        { immediate: true } // This ensures the watcher runs immediately if conditions are already met
        );
    });
    
};


const getVehiclesApi = async ()=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    const vehiclesStore = useVehiclesStore();
    const systemStore = useSystemStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        systemStore.enableLoadingStatus();
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        axios.get(apiEndpoint + '/' + dealerStore.getDealerData().id + "/vehicles/summary" ,configs)
        .then((response) => {
            if (response.status == 200) {
                vehiclesStore.setVehicles(response.data.data.vehicles)
                systemStore.disableLoadingStatus();
            }
        })
        .catch((error) => {
            systemStore.disableLoadingStatus();
        })
    }}

    const getVehiclesWithFiltersApi = async ()=> {
        const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    
        const authStore = useAuthStore();
        const dealerStore = useDealerStore();
        const vehiclesStore = useVehiclesStore();
        const systemStore = useSystemStore();
        if (authStore.authStatus && dealerStore.getDealerData().id) {
            systemStore.enableLoadingStatus();
            const configs = {
                headers: {
                    "x-auth-token" : authStore.getAuthToken()
                }
            }
            const requestEndpoint = ref('');
            if (vehiclesStore.filters.date === '') {
                requestEndpoint.value = apiEndpoint + '/' + dealerStore.getDealerData().id + "/vehicles/summary?filters=true&missing_images=" + vehiclesStore.filters.missing_images + "&missing_price=" + vehiclesStore.filters.missing_price + "&status=" + vehiclesStore.filters.status;
            } else {
                requestEndpoint.value = apiEndpoint + '/' + dealerStore.getDealerData().id + "/vehicles/summary?filters=true&missing_images=" + vehiclesStore.filters.missing_images + "&missing_price=" + vehiclesStore.filters.missing_price + "&status=" + vehiclesStore.filters.status + "&before=" + vehiclesStore.filters.date;
            }
            axios.get(requestEndpoint.value,configs)
            .then((response) => {
                if (response.status == 200) {
                    vehiclesStore.setVehicles(response.data.data.vehicles)
                    systemStore.disableLoadingStatus();
                }
            })
            .catch((error) => {
                systemStore.disableLoadingStatus();
            })
        }}
const deleteVehicle = async (file, dealer, data)=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    const vehiclesStore = useVehiclesStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        axios.get(apiEndpoint + '/dealer/' + dealerStore.getDealerData().id + "/tools/vin-decode/" + vin,configs)
        .then((response) => {
            vehiclesStore.setVehicles(response.data)
            if (response.status == 200) {
                vehiclesStore.setVehicles(response.data.data.vehicles)
            }
        })
        .catch((error) => {

        })
    }
}
export { addVehicle, editVehicle, getVehicle, getVehiclesApi, getVehiclesWithFiltersApi, deleteVehicle}