<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer'
import HeaderDealershipDropdown from './HeaderDealershipDropdown.vue'
import HeaderMenu from './HeaderMenu.vue'
const authStore = useAuthStore();
const dealerStore = useDealerStore();

const showSubMenu = ref(true);
const toggleMenu = () => {
  showSubMenu.value ? showSubMenu.value = false : showSubMenu.value = true;
}

const showMobileMenu = ref(false);
const toggleMobileMenu = () => {
  showMobileMenu.value ? showMobileMenu.value = false : showMobileMenu.value = true;
}
function closeMobileMenu() {
  showMobileMenu.value = false;
}

</script>
<template>
  <div class="w-full bg-[#00231f]">
    <div class="container grid grid-cols-5 md:grid-cols-12 mx-auto bg-red py-4 p-2 sm:py-2">
        <div class="col-span-2 text-white content-center">
            <img class="w-3/4 sm:w-24 bg-white rounded p-2" src="../assets/garage-register-e1716018524327.png" />
        </div>
        <HeaderMenu  class="hidden sm:block" />
        <div class="sm:hidden col-span-3 flex justify-end items-center">
        <div class="text-white text-[32px] px-4" v-on:click="toggleMobileMenu()">
            <i class="fa fa-bars" ></i>
        </div>
      </div>
      <HeaderDealershipDropdown class="hidden"></HeaderDealershipDropdown>
    </div>
  </div>
  <Teleport to="body">
    <Transition name="fade">

    <div v-if="showMobileMenu" class="fixed flex top-0 left-0 w-screen h-screen bg-[#00231f]">
      <div class="mobile-menu w-5/6 h-full bg-white flex flex-col py-4 px-4 justify-start gap-4">
        <RouterLink to="/" v-on:click="closeMobileMenu()"><img class="w-32" src="../assets/garage-register-e1716018524327.png" /></RouterLink>

        <div class="top-12 rounded bg-white text-black p-2">
          <HeaderDealershipDropdown></HeaderDealershipDropdown>
        </div>
        <HeaderMenu @closeMobileMenu="closeMobileMenu()" />

      </div>
      <div class="text-white w-1/6 text-[32px] px-4 text-center" v-on:click="toggleMobileMenu()">
              <i class="fa fa-close pt-8 m-auto" ></i>
      </div>
    </div>
  </Transition>

  </Teleport>
</template>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>