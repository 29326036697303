<script setup>
    import { ref, computed } from "vue";
    import axios from "axios";
    import { useDealerStore } from "../../stores/dealer";
    import { useAuthStore } from "../../stores/auth"

    const emit = defineEmits(['selectVehicle'])

    const authStore = useAuthStore();
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const searchKeyword = ref('');
    const vehicles_array = ref([])
    const vehiclesRequested = ref(false);
    const filteredVehicles = computed(() => {
        const filtered = ref([])

        vehicles_array.value.filter((vehicle) => {
            const vehicle_name = vehicle.vehicle_year + ' ' + vehicle.vehicle_make.toLowerCase() + ' ' + vehicle.vehicle_model.toLowerCase();
            if (vehicle_name.includes(searchKeyword.value.toLowerCase()) || vehicle.vehicle_stock_no.toLowerCase().includes(searchKeyword.value.toLowerCase())) {
                filtered.value.push(vehicle);
            }
        })
        return filtered.value;
    })
    const dropdownStatus = ref(false);

    function searchVehicle() {
        dropdownStatus.value = true;
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        const statuses = ['in_stock', 'pending']

        if (searchKeyword.value.length > 1 && vehicles_array.value.length === 0 && !vehiclesRequested.value) {
            vehiclesRequested.value = true;
            axios.get(apiEndpoint + '/' + useDealerStore().dealerData.id + '/vehicles/stock/?status=' + statuses, configs)
            .then((response) => {
                vehicles_array.value = response.data.data;
            })
        }
        if (searchKeyword.value.length > 3) {
        }
    }
    function selectVehicle(vehicle) {
        emit("selectVehicle", vehicle)
        dropdownStatus.value = false;
    }

</script>
<template>
    <label class="text-xs">Search Inventory</label>
    <div class="flex">
        <div class="relative">
            <input type="text" class="border px-2 rounded " v-model="searchKeyword" v-on:keyup="searchVehicle()" />
            <div v-if="dropdownStatus" class="absolute border h-60 w-80 bg-white rounded shadow px-2 py-1 overflow-y-scroll mt-1">
                <div v-for="vehicle in filteredVehicles" class="border-b py-1 px-2 text-sm w-full overflow-hidden rounded flex flex-col hover:bg-gray-200 cursor-pointer hover:rounded" v-on:click="selectVehicle(vehicle)">
                    <span class="font-bold">{{ vehicle.vehicle_year + ' ' + vehicle.vehicle_make + ' ' + vehicle.vehicle_model }}</span>
                    <span class="text-xs">Stock #: {{ vehicle.vehicle_stock_no }} - VIN: {{ vehicle.vehicle_vin }}</span>
                </div>
                <div v-if="filteredVehicles.length < 1" class="border-b py-1 px-2 text-sm w-full overflow-hidden rounded flex flex-col hover:bg-gray-200 cursor-pointer hover:rounded" v-on:click="selectVehicle(vehicle)">
                    No vehicles found
                </div>
            </div>
        </div>
    </div>

</template>