<script setup>
import { useAuthStore } from '../stores/auth';
import { ref, computed, reactive } from 'vue';

const authStore = useAuthStore();
const credentials = ref({
    email: '',
    password: '',
    remember: false
})

const loginButtonStatus = computed(() => {
    if (credentials.value.email != '' && credentials.value.password != '') {
        return false;
    } else {
        return true;
    }
    console.log('test')
})

function login() {
    authStore.login(credentials.value.email, credentials.value.password);
}
</script>
<template>
    <div class="sm:h-screen flex flex-col-reverse sm:grid sm:grid-cols-2  justify-stretch items-center">
  <div class="bg-login-background bg-cover h-full "></div>
  <div class="grid justify-center sm:content-center relative sm:h-full">
    <div class="flex flex-col items-center justify-center mt-8 sm:-mt-10 pb-8">
      <img class="w-48" src="../assets/garage-register-e1716018524327.png" />
      <p class="mt-4">Use your email and password to login.</p>
    </div>
    <div class="w-96 border rounded-md flex flex-col gap-4 p-4">
      <div v-if="authStore.errorStatus" class="border border-red-600 rounded p-2 text-sm bg-red-100">
        {{authStore.errorMessage}}
      </div>

      <div class="flex flex-col gap-1">
        <label>Email</label>
        <input type="text" v-model="credentials.email" class="rounded border h-9"  :disabled="authStore.loginStatus"/>
      </div>
      <div class="flex flex-col gap-1">
        <label>Password</label>
        <input type="password" v-model="credentials.password" class="rounded border h-9" :disabled="authStore.loginStatus" v-on:keyup.enter="login()" />
      </div>
      <div class="flex">
        <input id="remember" type="checkbox" v-model="credentials.remember"  :disabled="authStore.loginStatus" />
        <label class="ml-2 text-[14px] cursor-pointer" for="remember">Remember me for 30 days</label>
      </div>
      <div>
        <button class="bg-gr-green text-white hover:bg-[#394c52] disabled:bg-gray-300 disabled:text-gray-400 w-full p-2 rounded shadow" :disabled="loginButtonStatus || authStore.loginStatus" v-on:click="login()">Login</button>
      </div>
      <div class="flex justify-between">
        <div class="text-xs">Forgot Password?</div>
        <div class="text-xs">New to Garage Register? Signup</div>
      </div>
    </div>
    <div class="flex justify-center mt-4">
      <p class="text-[14px] hover:underline cursor-pointer">Need help signing in?</p>
    </div>
    <!-- <div class="absolute left-0 right-0 bottom-4 flex justify-center">
      <p class="text-sm">Powered by DealerSwift</p>
    </div> -->
  </div>
</div>
</template>