<script setup>
import { ref } from "vue";
import {useFileDropBoxStore} from "../stores/filedropbox.js"
import axios from 'axios'
import { VueDraggableNext  } from 'vue-draggable-next'

const fileDropBoxStore = useFileDropBoxStore();
const files = ref([]);
const fileInput = ref({
    files: []
})

const file = ref(null);

const toBeUploaded = ref([]);
const uploadedFiles = ref([]);
const imageId = ref(1);

const STORAGE_ZONE_NAME = 'dealer-swift'
const API_KEY = '1eae9203-6eed-4267-92603e6ae97d-5796-4f2f'

const isDragging = ref(false);

const onChange = async (e) => {
  if (files.value.length === 0 && document.getElementById('filesInput').files.length > 0) {
    toBeUploaded.value.push(...document.getElementById('filesInput').files)
    uploadImages()
    resetInput()
  }


}

const dragover = (e) => {
    e.preventDefault();
    isDragging.value = true;
}

const dragleave = (e) => {
    isDragging.value = false;
}
const drop = (e) => {
    e.preventDefault();
    toBeUploaded.value = e.dataTransfer.files
    uploadImages();
    isDragging.value = false;
    files.value = [];
}

const generateURL = (file) => {
    let fileSrc = URL.createObjectURL(file);
    setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
    }, 1000);
    return fileSrc;
}
const chooseFiles = () => {
    document.getElementById("filesInput").click()
}

const resetInput = () => {
    file.value = null;
}
function generateUUID() {
    // Create a version 4 UUID
    return '4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
function getFileExtension(filename) {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === filename.length - 1) {
        return ''; // No extension or the filename ends with a dot
    }
    return filename.substring(lastDotIndex + 1);
}
function getNextId(objectsArray) {
    // Check if the array is empty
    if (objectsArray.length === 0) {
        return 1; // If empty, start with ID 1
    }

    // Map through the array to extract ids
    const ids = objectsArray.map(obj => obj.id);

    // Get the maximum id
    const maxId = Math.max(...ids);

    // Return the next id
    return maxId + 1;
}

const uploadImages = async () => {
    console.log('uploadImages: ' + toBeUploaded.value.length )
    if (toBeUploaded.value.length > 0) {
        for (const file of toBeUploaded.value) {
            console.log(file)
            const formData = new FormData()
            formData.append('file', file)
            const filename = generateUUID();
            try {
                //const response = await axios.put('https://ny.storage.bunnycdn.com/' + STORAGE_ZONE_NAME + '/' + fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId +'/' + imageId.value + '2b9jd4n'+ encodeURI(file.name), file, {

                const response = await axios.put('https://ny.storage.bunnycdn.com/' + STORAGE_ZONE_NAME + '/' + fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId +'/' + filename + "." + getFileExtension(file.name), file, {
                headers: {
                    'AccessKey': API_KEY,
                    'Content-Type': 'application/octet-stream'
                }
                })
                console.log('Upload success', response.data)
                if (response.status === 201) {
                    const newImageId = getNextId(fileDropBoxStore.uploadedFiles);
                    console.log({
                        id: newImageId,
                        fileName: encodeURI(file.name),
                        path: fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name),
                        link: 'https://img-cdn.dealerswift.com/' + fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name)
                    })
                    uploadedFiles.value.push({
                        id: newImageId,
                        fileName: file.name,
                        path: fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name),
                        link: 'https://img-cdn.dealerswift.com/' + fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name)
                    })
                    fileDropBoxStore.addToList({
                        id: newImageId,
                        fileName: file.name,
                        path: fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name),
                        link: 'https://img-cdn.dealerswift.com/' + fileDropBoxStore.dealerId() + '/' + fileDropBoxStore.vehicleId + '/' + filename + "." + getFileExtension(file.name)
                    })
                    imageId.value++;
                }
            } catch (error) {
                console.error('Upload error', error)
            }
        }
    }
    toBeUploaded.value = [];
}

const deleteFile = async (index, path) => {
    try {
        const response = await axios.delete('https://ny.storage.bunnycdn.com/' + STORAGE_ZONE_NAME + '/' + path, {
        headers: {
            'AccessKey': API_KEY,
            'Content-Type': 'application/octet-stream'
        }
        })
        console.log('Delete success', response.data)
        if (response.status === 200) {
            fileDropBoxStore.uploadedFiles.splice(index,1)
        }
    } catch (error) {
        fileDropBoxStore.uploadedFiles.splice(index,1)

        console.error('Upload error', error)
    }

}
function moveAndPush(arr, index) {
    if (index < arr.length && index > 0) {
        // Move the element at the given index to the start
        let element = arr.splice(index, 1)[0]; // Remove the element at the index
        arr.unshift(element); // Insert it at the start of the array
    }
    console.log(arr); // to see the modified array
}

</script>

<template>
    <div class="grid justify-items-center content-center py-4 gap-2 border rounded-md border-dashed" 
    :class="{'border-gr-green border-2' : isDragging, ' border-2 ' : !isDragging}" 
    v-on:dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" multiple name="files" id="filesInput" class="hidden" @click="resetInput()" @change="onChange(e)" ref="file" accept=".jpg, .jpeg, .png, .png" />
        <label for="filesInput" class="grid justify-items-center content-center gap-2" >
            <div v-if="!isDragging" class="text-gray-600 font-semibold text-lg">Drag files here</div>
            <div v-if="isDragging" class="text-gray-600 font-semibold text-lg">Drop file(s) here</div>
            <div class="text-gray-600 text-xs">OR</div>
            <button class="bg-gr-green text-white rounded px-2 py-1" @click="chooseFiles()">Select Files</button>
        </label>
    </div>
    <div >
        <VueDraggableNext v-model="fileDropBoxStore.uploadedFiles" class=" grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-9 3xl:grid-cols-10 items-stretch gap-2 mx-2">
            <transition-group>
                <div v-for="(file, index) in fileDropBoxStore.uploadedFiles" :key="file.id" class="flex flex-col bg-gray-200 border shadow-sm rounded-md gap-1">
                    <img :src="file.link + '?width=150'" width="200px" class="bg-white aspect-4/3 object-cover w-full rounded-md"/> 
                    <div class="flex flex-row justify-center gap-3 mt-1 mb-2">
                        <button class="bg-gr-green text-white px-2 py-1 rounded text-xs cursor-pointer disabled:bg-gray-300" :disabled="index == 0" v-on:click="moveAndPush(fileDropBoxStore.uploadedFiles, index)">Set Primary</button>
                        <div class="hover:bg-red-500 hover:text-white bg-gray-300 active:scale-125 px-2 py-1 rounded text-xs cursor-pointer" v-on:click="deleteFile(index, file.path)">Delete</div>
                    </div>
                </div>
            </transition-group>
        </VueDraggableNext>
    </div>
    <div>
        <div class="mt-2 text-xs text-red-600"><strong>* Please note:</strong> deleting the image here will prementatly delete it from the storage even if the changes are not saved to the vehicle.</div>
        <div class="mt-2 text-xs text-red-600">{{ fileDropBoxStore.vehicleId }}</div>
    </div>
</template>