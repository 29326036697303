<script setup>
import { ref, watch, onMounted, onUnmounted} from 'vue';
import axios from 'axios';

const props = defineProps({
    salesperson: Object,
    editSalespersonPopupStatus: Boolean,
    success: Boolean,
    error: Boolean
})

onMounted(() => {
    console.log('helooooo')
})
const edited_salesperson = ref({})
const salesperson_errors = ref({
    name: false,
    role: false,
    registration_no: false
})

const emit = defineEmits(['ProcessMemberEdit', 'ClosePopup'])

function submitEditedSalesperson() {
    emit('ProcessMemberEdit', props.salesperson)
}


</script>
<template>
    <Transition>
        <div v-if="editSalespersonPopupStatus" class="w-screen h-screen bg-black bg-opacity-60 fixed left-0 top-0 flex justify-center items-center">
            <div class="relative bg-white rounded py-8 px-6 flex flex-col gap-2 mx-2">
                <div class="absolute right-4 top-4"><i  v-on:click="emit('ClosePopup')" class="p-1.5 bg-gray-300 hover:bg-gray-400 cursor-pointer rounded fa-solid fa-xmark"></i></div>
                <h4 class="text-[24px] font-semibold"><i class="text-gr-green fa-solid fa-user-plus"></i> Edit Sales Team Member</h4>
                <p v-if="success" class="border border-gr-green rounded p-2 bg-green-100 text-green-700"><i class="fa-solid fa-check"></i> Member updated successfully</p>
                <p v-if="error" class="border border-red-700 rounded p-2 bg-red-100 text-red-700"><i class="fa-solid fa-circle-exclamation"></i> We've encountered an error while updating</p>
                <p>Enter the information below:</p>
                <div class="grid grid-cols-2 gap-2 my-2">
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Full Name: </label>
                        <input type="text" class="rounded border" v-model="salesperson.name" :class="{'border-red-500' : salesperson_errors.name}"/>
                        <span v-if="salesperson_errors.name" class="text-xs text-red-600">This field is required.</span>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Role: </label>
                        <select class="rounded border"  v-model="salesperson.role"  :class="{'border-red-500' : salesperson_errors.role}">
                            <option value="Salesperson">Salesperson</option>
                            <option value="Sales Manager">Sales Manager</option>
                            <option value="General Sales Manager">General Sales Manager</option>
                            <option value="Finance Manager">Finance Manager</option>
                        </select>
                        <span v-if="salesperson_errors.role" class="text-xs text-red-600">This field is required.</span>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Registration No.: </label>
                        <input type="number" class="rounded border" v-model="salesperson.registration_no"/>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Registration Expiry Date: </label>
                        <input type="date" class="rounded border" v-model="salesperson.registration_expiry"/>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Registration Expiry Date: </label>
                        <select class="rounded border"  v-model="salesperson.status">
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div>
                </div>
                <div class="flex mt-4 gap-4">
                    <button class="bg-gr-green rounded text-white text-lg py-1.5 px-6" v-on:click="submitEditedSalesperson()">Update</button>
                    <button class="bg-red-500 rounded text-white text-lg py-1.5 px-6" v-on:click="emit('ClosePopup')">Close</button>
                </div>
            </div>
        </div>
    </Transition>

</template>