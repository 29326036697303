<script setup>
import { PDFDocument, StandardFonts} from 'pdf-lib';
import { useDealerStore } from '../../stores/dealer';

const dealerStore = useDealerStore();


function toUpperCase(str) {
    return str.toUpperCase();
}

async function fillAndFlattenPdf(url, formData) {

const formPdfBytes = await fetch('http://192.168.50.235:5173/src/assets/files/billofsale5.pdf').then(res => res.arrayBuffer());
const pdfDoc = await PDFDocument.load(formPdfBytes);
const courierBoldFont = await pdfDoc.embedFont('Courier-Bold'); // Embed Courier font
const helveticaBoldFont = await pdfDoc.embedFont('Helvetica-Bold'); // Embed Courier font

  // Embed the Courier Bold font

const form = pdfDoc.getForm();
form.getTextField('SUBTOTAL').setText(formData.fieldName);
form.getTextField('VIN').setText('MKF63D6G2V5D8F41D');
form.getTextField('DEALER_REGISTRATION_NO').setText('169855821');
form.getTextField('ACCEPTOR_NAME').setText('YOUSIF BODAGH');
form.getTextField('VIN').setText('MKF63D6G2V5D8F41D');
form.getTextField('LINE_2_DESC').setText('REAR VIEW MIRROR AND SENSORS');
form.getTextField('LINE_1_DESC').setText('D/I BACKUP CAMERA');
// Fill more form fields...
form.updateFieldAppearances(courierBoldFont);
const dealer_name = form.getTextField('DEALER_NAME');
dealer_name.setText(toUpperCase(dealerStore.dealerData.dealer_name));
dealer_name.updateAppearances(helveticaBoldFont);
const address = form.getTextField('DEALER_ADDRESS');
address.setText(toUpperCase(dealerStore.dealerData.dealer_address.street_address) + '\n' + toUpperCase(dealerStore.dealerData.dealer_address.city) + ', ' + toUpperCase(dealerStore.dealerData.dealer_address.postal_code));
address.updateAppearances(helveticaBoldFont)

const reg_info = form.getTextField('DEALER_REG_INFO');
reg_info.setText('MVDA REG. #: 5277637 \nH.S.T #: 83725 2287 RT0001');
reg_info.updateAppearances(helveticaBoldFont)


// Flatten the form to lock the fields
form.flatten();

// Save the PDF and download
const pdfBytes = await pdfDoc.save();
const blob = new Blob([pdfBytes], { type: 'application/pdf' });
const urlBlob = URL.createObjectURL(blob);

// Open the PDF in a new tab
//window.open(urlBlob, '_blank');


    // Open the PDF in a new tab
    const newTab = window.open(urlBlob, '_blank');
newTab.onload = function () {
    newTab.print(); // Automatically trigger the print dialog when the PDF loads
};
}
</script>

<template>
    <div class="border bg-white min-h-48 m-auto w-96 rounded-md flex flex-col justify-center items-center">
        <div class="flex text-sm gap-2">
            <div>Loading deal information...</div>
            <div class="font-bold text-gr-green">Success</div>
        </div>
        <div class="flex text-sm gap-2">
            <div>Loading vehicle information...</div>
            <div class="font-bold text-gr-green">Success</div>
        </div>
        <div class="flex text-sm gap-2">
            <div>Loading customer information...</div>
            <div class="font-bold text-gr-green">Success</div>
        </div>
        <div class="flex text-sm gap-2">
            <div>Loading warranty & insurance information...</div>
            <div class="font-bold text-gr-green">Success</div>
        </div>
    </div>
    <p>Click the button to create a new PDF document with <code>pdf-lib</code></p>
    <button v-on:click="fillAndFlattenPdf('', { fieldName: 'Sample Text' })">Create PDFs</button>

</template>