<script setup>
    import { ref, onMounted, computed, reactive } from "vue";
    import DealAddons from '../components/Deals/DealAddons.vue'
    import axios from 'axios'
    import { useDealerStore } from "../stores/dealer";
    import { useAuthStore } from "../stores/auth"
    import SearchInventory from '../components/Deals/SearchInventory.vue'
    import DealExtraAddons from "../components/Deals/DealExtraAddons.vue";
    import { PDFDocument, StandardFonts} from 'pdf-lib';
    import { useSystemStore } from "../stores/system";
    import { getVehicle } from "../services/vehicle_apis";
    import { useRouter, useRoute } from "vue-router";
    import router from "../router";
    import * as Sentry from "@sentry/vue"

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    //const apiEndpoint = 'https://enkkcrbfzfenp.x.pipedream.net';

    function selectVehicle(element) {
        try {
            deal.value.vehicle_price = element.vehicle_settings.regular_price
            vehicle.value.stock_number = element.vehicle_stock_no
            vehicle.value.vin = element.vehicle_vin
            vehicle.value.year = element.vehicle_year
            vehicle.value.make = element.vehicle_make
            vehicle.value.model = element.vehicle_model
            vehicle.value.trim = element.vehicle_specifications.trim
            vehicle.value.model = element.vehicle_model
            vehicle.value.odometer = element.vehicle_mileage
            vehicle.value.certified = element.vehicle_settings.certified
            vehicle.value.mto_brand = element.vehicle_settings.mto_brand
            vehicle.value.daily_rental = element.vehicle_settings.daily_rental
            vehicle.value.exterior_color = element.vehicle_settings.exterior_color['Generic Name']
            deal.value.vehicle_id = element.vehicle_id
        } catch (error) {
            console.log(error);
        }
    }
    const makes = ref();
    const customer = ref({
        firstname: "",
        lastname: "",
        middlename: "",
        email: "",
        phone: '',
        driver_license: '',
        driver_license_expiry: '',
        date_of_birth: '',
        street_address: '',
        street_address2: '',
        city: '',
        postal_code: '',
        province: ''
    })
    // Addons Start
    const deal_addons = reactive([]);

    const deal = ref({
        deal_number: 1001,
        vehicle_price: 0,
        addons: deal_addons,
        extended_warranty: {
            status: false,
            provider: "",
            term: "",
            mileage: "",
            cost: 0
        },
        gap_insurance: {
            status: false,
            provider: "",
            term: "",
            mileage: "",
            cost: 0
        },
        insurance: {
            policy_number: "",
            company: "",
            insurer_phone: "",
            policy_expiry: ""
        },
        licensing_fee: 0,
        gasoline: 0,
        trade_in: false,
        trade_in_id : null,
        deposit: 0,
        delivery_note: '',
        delivery_date: '',
        deal_date: '',
        deal_number: '',
        deal_status: '',
        deal_notes: '',
        deal_comments: '',
        salesperson: {
            name: '',
            registration: ''
        },
        sales_manager: {
            name: '',
            registration: ''
        }

    })

    function addAddon(addon) {
        deal_addons.push(addon)
    }
    function removeAddon(index) {
        deal_addons.splice(index, 1);
    }

    function addons_total () {
        let a_t = 0;
        deal_addons.forEach((addon) => {
            a_t = parseFloat(a_t) + parseFloat(addon.price)
        })
        return parseFloat(a_t)
    }
    //Addons End


    const subtotal = computed(() => {
        return (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + addons_total() ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    })

    const net_difference = computed(() => {
        let st;
        if (deal.value.trade_in && trade_in.value.trade_in_value > 0) {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total())) - parseFloat(trade_in.value.trade_in_value);
        } else {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total()));
        }

        return parseFloat(st).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    })

    const tax = computed(() => {
        let st;
        if (deal.value.trade_in && trade_in.value.trade_in_value > 0) {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total())) - parseFloat(trade_in.value.trade_in_value);
        } else {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total()));
        }
        
        return parseFloat(st * 0.13).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    })

    const subtotal2 = computed(() => {
        let st;
        if (deal.value.trade_in && trade_in.value.trade_in_value > 0) {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total())) - parseFloat(trade_in.value.trade_in_value);
        } else {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total()));
        }
        let tax = st * 0.13;
        let after_tax = 0;
        if (deal.value.gasoline > 0) {
            after_tax = after_tax + deal.value.gasoline;
        }
        if (deal.value.licensing_fee) {
            after_tax = after_tax + deal.value.licensing_fee
        }
        if (deal.value.trade_in && trade_in.value.lien_payout > 0) {
            after_tax = after_tax + trade_in.value.lien_payout
        }

        return parseFloat(st + tax + after_tax).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    })

    const total_balance_due = computed(() => {
        let st;
        if (deal.value.trade_in && trade_in.value.trade_in_value > 0) {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total())) - parseFloat(trade_in.value.trade_in_value);
        } else {
            st = (parseFloat(deal.value.vehicle_price) + parseFloat(deal.value.extended_warranty.cost) + parseFloat(addons_total()));
        }
        let tax = st * 0.13;
        let after_tax = 0;
        if (deal.value.gasoline > 0) {
            after_tax = after_tax + deal.value.gasoline;
        }
        if (deal.value.licensing_fee) {
            after_tax = after_tax + deal.value.licensing_fee
        }

        return parseFloat(st + tax + after_tax - deal.value.deposit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    })
    const vehicle = ref({
        stock_number: "",
        vin: "",
        year: "",
        make: "",
        model: "",
        trim: "",
        odometer: "",
        odometer_unit: "",
        mto_brand: "",
        exterior_color: "",
        daily_rental: null,
        certified: null,
        delivery_date:"",
        delivery_note: ""
    })

    const trade_in = ref({
        vin: "",
        year: "",
        make: "",
        model: "",
        trim: "",
        odometer: "",
        odometer_unit: "",
        mto_brand: "",
        exterior_color: "",
        accidents: "",
        disclosures: "",
        trade_in_value: null,
        lien_payout: null,
        lien_holder: ""

    })
    onMounted(() => {
        if (typeof useRoute().query.vehicle !== 'undefined' ) {
            LoadVehicleData(useRoute().query.vehicle);
        } else {
            console.log('not found')
        }

        window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        customer.value.lastname.focus()
      }
    });
    //          deal.value.vehicle_price = element.vehicle_settings.regular_price
    //         vehicle.value.stock_number = element.vehicle_stock_no
    //         vehicle.value.vin = element.vehicle_vin
    //         vehicle.value.year = element.vehicle_year
    //         vehicle.value.make = element.vehicle_make
    //         vehicle.value.model = element.vehicle_model
    //         vehicle.value.trim = element.vehicle_specifications.trim
    //         vehicle.value.model = element.vehicle_model
    //         vehicle.value.odometer = element.vehicle_mileage
    //         vehicle.value.certified = element.vehicle_settings.certified
    //         vehicle.value.mto_brand = element.vehicle_settings.mto_brand
    //         vehicle.value.daily_rental = element.vehicle_settings.daily_rental
    //         vehicle.value.exterior_color = element.vehicle_settings.exterior_color['Generic Name']
    //         deal.value.vehicle_id = element.vehicle_id

    async function LoadVehicleData(vehicle_id) {
        const vehicle_dataa = await getVehicle(vehicle_id);
        if (vehicle_dataa?.status === 200) {
            const vehicle_clean_data = ref({
                vehicle_settings: {
                    regular_price: vehicle_dataa.data.data.vehicle_settings.regular_price,
                    certified: vehicle_dataa.data.data.vehicle_settings.certified,
                    mto_brand: vehicle_dataa.data.data.vehicle_settings.mto_brand,
                    daily_rental: vehicle_dataa.data.data.vehicle_settings.daily_rental,
                    exterior_color: vehicle_dataa.data.data.vehicle_settings.exterior_color,
                },
                vehicle_id: vehicle_dataa.data.data.vehicle_id,
                vehicle_vin: vehicle_dataa.data.data.vin,
                vehicle_year: vehicle_dataa.data.data.year,
                vehicle_model: vehicle_dataa.data.data.model,
                vehicle_make: vehicle_dataa.data.data.make,
                vehicle_mileage: vehicle_dataa.data.data.mileage,
                vehicle_stock_no: vehicle_dataa.data.data.stock_number,
                vehicle_specifications: vehicle_dataa.data.data.vehicle_specifications
            })
            selectVehicle(vehicle_clean_data.value)
        }

    }

    axios.get('https://auto.dev/api/models?apikey=ZrQEPSkKeW91c2lmQGxldmVsdXBzb2x1dGlvbnMuY28=')
        .then((response) => {
            if (response.status === 200) {
                makes.value = Object.keys(response.data)
                useSystemStore().disableLoadingStatus();

            } else {
                useSystemStore().disableLoadingStatus();
            }
        })
        .catch((error) => {
            useSystemStore().disableLoadingStatus();
        })

    })
    function toUpperCase(str) {
        return str.toUpperCase();
    }
    const pricing_data = ref({
        subtotal: subtotal,
        subtotal2: subtotal2,
        tax: tax,
        addons_total: addons_total,
        gasoline: deal.value.gasoline,
        licensing_fee: deal.value.licensing_fee,
        deposit: deal.value.deposit,
        tradein: trade_in.value.trade_in_value,
        lien_payout: trade_in.value.lien_payout
    })

    async function fillAndFlattenPdf() {
        if (validateFields()) {
            return;
        }

        try {
            const formPdfBytes = await fetch('https://api.garageregister.ca/files/billofsale5.ccs').then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(formPdfBytes);
            const courierBoldFont = await pdfDoc.embedFont('Courier-Bold'); // Embed Courier font
            const helveticaBoldFont = await pdfDoc.embedFont('Helvetica-Bold'); // Embed Courier font

            // Embed the Courier Bold font

            const form = pdfDoc.getForm();

            //Vehicle
            form.getTextField('SELLING_PRICE').setText('$' + deal.value.vehicle_price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            form.getTextField('SUBTOTAL').setText('$' + subtotal.value);
            form.getTextField('VIN').setText(vehicle.value.vin);
            form.getTextField('YEAR').setText(vehicle.value.year.toString());
            form.getTextField('MAKE').setText(toUpperCase(vehicle.value.make));
            form.getTextField('MODEL').setText(toUpperCase(vehicle.value.model));
            form.getTextField('TRIM_LEVEL').setText(toUpperCase(vehicle.value.trim));
            form.getTextField('COLOR').setText(toUpperCase(vehicle.value.exterior_color));
            form.getTextField('STOCK_NUMBER').setText(toUpperCase(vehicle.value.stock_number));
            form.getTextField('DISTANCE_TRAVELLED').setText(vehicle.value.odometer.toString());
            form.getTextField('MTO_BRAND').setText(toUpperCase(vehicle.value.mto_brand));
            if (vehicle.value.certified) {
                form.getRadioGroup('WITH A SAFETY STANDARDS CERTIFICATE').select('YES')
            } else {
                form.getRadioGroup('WITH A SAFETY STANDARDS CERTIFICATE').select('NO')
            }
            if (vehicle.value.daily_rental) {
                form.getCheckBox('DAILY_RENTAL').check();
            }
            form.getTextField('DETAILS_OF_DELIVERY').setText(toUpperCase(deal.value.delivery_note));
            form.getTextField('DELIVERY_DATE').setText(toUpperCase(deal.value.delivery_date));
            form.getTextField('COMMENT_LINE_1').setText(toUpperCase(deal.value.deal_comments));

            // Customers
            form.getTextField('PURCHASER_NAME').setText(toUpperCase(customer.value.firstname + ' ' + customer.value.lastname));
            form.getTextField('PURCHASER_ADDRESS').setText(toUpperCase(customer.value.street_address));
            form.getTextField('CITY').setText(toUpperCase(customer.value.city));
            form.getTextField('PROVINCE').setText(toUpperCase(customer.value.province));
            form.getTextField('POSTAL_CODE').setText(toUpperCase(customer.value.postal_code));
            form.getTextField('HOME_PHONENUMBER').setText(toUpperCase(customer.value.phone));
            form.getTextField('DRIVERS_LICENCE_NO').setText(toUpperCase(customer.value.driver_license));
            form.getTextField('DL_EXPIRY_DATE').setText(toUpperCase(customer.value.driver_license_expiry));
            form.getTextField('EMAIL_ADDRESS').setText(toUpperCase(customer.value.email));
            // form.getTextField('').setText(toUpperCase());
            // form.getTextField('').setText(toUpperCase());

            //DEAL
            const [deal_year, deal_month, deal_day] = deal.value.deal_date.split('-');

            form.getTextField('DATE_DAY').setText(deal_day);
            form.getTextField('DATE_MONTH').setText(deal_month);
            form.getTextField('DATE_YEAR').setText(deal_year);

            form.getTextField('DEALER_REGISTRATION_NO').setText(toUpperCase(dealerStore.dealerData.dealer_settings.registration.dealer_number));
            form.getTextField('SALESPERSON_NAME').setText(toUpperCase(deal.value.salesperson.name));
            form.getTextField('SALESPERSON_REG_NO').setText(toUpperCase(deal.value.salesperson.registration_no));

            form.getTextField('ACCEPTOR_NAME').setText(toUpperCase(deal.value.sales_manager.name));
            form.getTextField('ACCEPTOR_TITLE').setText(toUpperCase(deal.value.sales_manager.role));
            form.getTextField('MANAGER_REG_NO').setText(deal.value.sales_manager.registration_no);
            form.getTextField('ACCEPTOR_DATE').setText(deal.value.deal_date);


            // DEALER GUARANTY
            if (dealerStore.dealerData.dealer_settings.settings.deals.dealer_guaranty.status && vehicle.value.certified) {
                form.getTextField('DEALER_GUARANTY_DAYS').setText(dealerStore.dealerData.dealer_settings.settings.deals.dealer_guaranty.days.toString());
                form.getTextField('DEALER_GUARANTY_KMS').setText(dealerStore.dealerData.dealer_settings.settings.deals.dealer_guaranty.mileage.toString());
                form.getTextField('DEALER_GUARANTY_DESCRIPTION_1').setText(toUpperCase(dealerStore.dealerData.dealer_settings.settings.deals.dealer_guaranty.description));
                form.getCheckBox('DEALER_GUARANTY_YES').check();
            } else {
                form.getCheckBox('DEALER_GUARANTY_NO').check();
            }

            // TRADE IN FIELDS
            if (deal.value.trade_in) {
                form.getTextField('TRADE_VIN').setText(toUpperCase(trade_in.value.vin));
                form.getTextField('TRADE_MAKE').setText(toUpperCase(trade_in.value.make));
                form.getTextField('TRADE_MODEL').setText(toUpperCase(trade_in.value.model));
                form.getTextField('TRADE_YEAR').setText(toUpperCase(trade_in.value.year));
                form.getTextField('TRADE_TRIM').setText(toUpperCase(trade_in.value.trim));
                form.getTextField('TRADE_COLOUR').setText(toUpperCase(trade_in.value.exterior_color));
                form.getTextField('TRADE_DISTANCE').setText(toUpperCase(trade_in.value.odometer));
                form.getTextField('LIEN_HOLDER').setText(toUpperCase(trade_in.value.lien_holder));
                form.getTextField('AMOUNT').setText("$" + trade_in.value.lien_payout);
                if (trade_in.value.lien_payout > 0) {
                    form.getCheckBox('CB_TRADE_LIEN_YES').check();
                } else {
                    form.getCheckBox('CB_TRADE_LIEN_NO').check();
                }
            }

            // INSURANCE
            form.getTextField('INSURANCE_COMPANY_NAME').setText(toUpperCase(deal.value.insurance.company));
            form.getTextField('INSURANCE_POLICY_NO').setText(toUpperCase(deal.value.insurance.policy_number));
            form.getTextField('INSURANCE_EXPIRY_DATE').setText(toUpperCase(deal.value.insurance.policy_expiry));
            form.getTextField('INSURANCE_AGENT_NAME_PHONE').setText(toUpperCase(deal.value.insurance.insurer_phone));

            // WARRANTY
            if (deal.value.extended_warranty.status) {
                form.getTextField('EXTENDED_WARRANTY_RETAIL_VALUE').setText(toUpperCase(deal.value.extended_warranty.cost.toString()));
                form.getTextField('EXTENDED_WARRANY_COMPANY').setText(toUpperCase(deal.value.extended_warranty.provider));
                form.getTextField('EXTENDED_WARRANTY_MONTHS').setText(toUpperCase(deal.value.extended_warranty.term));
                form.getTextField('EXTENDED_WARRANTY_KMS').setText(toUpperCase(deal.value.extended_warranty.mileage.toString()));
                form.getCheckBox('EXTENDED_WARRANTY_YES').check();
            } else {
                form.getCheckBox('EXTENDED_WARRANTY_NO').check();
            }


            // PRICE FIELDS
            form.getTextField('HST_ON_NET_DIFFERENCE').setText('$' + tax.value)
            form.getTextField('NET_DIFFERENCE').setText('$' + net_difference.value)
            if (deal.value.licensing_fee > 0) {
                form.getTextField('LICENCE_FEE').setText('$' + deal.value.licensing_fee)
            } else {
                form.getTextField('LICENCE_FEE').setText('EXTRA')
            }
            if (deal.value.gasoline > 0) {
                form.getTextField('GASOLINE').setText('$' + deal.value.gasoline)
            } 

            if (deal.value.trade_in && trade_in.value.trade_in_value > 0) {
                form.getTextField('TRADE_IN_ALLOWANCE').setText('$' + trade_in.value.trade_in_value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
            if (deal.value.trade_in && trade_in.value.lien_payout > 0) {
                form.getTextField('PAYOUT_LIEN_TRADEIN').setText('$' + trade_in.value.lien_payout.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
            form.getTextField('SUBTOTAL_2').setText('$' + subtotal2.value)
            if (deal.value.deposit > 0) {
                form.getTextField('DEPOSIT_AMOUNT').setText('$' + deal.value.deposit.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            } else {
                form.getTextField('DEPOSIT_AMOUNT').setText('$0.00')
            }
            form.getTextField('TOTAL_BALANCE_DUE').setText('$' + total_balance_due.value)


            // ADDONS
            let addons_count = 0;
            deal_addons.forEach((addon) => {
                if (addons_count === 0) {
                    form.getTextField('OMVIC_DESC').setText(toUpperCase(addon.label));
                    form.getTextField('OMVIC').setText('$' + addon.price);

                } else {
                    form.getTextField('LINE_'+ addons_count+'_DESC').setText(toUpperCase(addon.label));
                    form.getTextField('LINE_'+ addons_count+'_AMOUNT').setText('$' + addon.price);
                }
                addons_count++;
            })
            // Fill more form fields...
            form.updateFieldAppearances(courierBoldFont);
            if (deal.value.extended_warranty.status && deal.value.extended_warranty.cost > 0) {
                form.getTextField('EXTENDED_WARRANTY_AMOUNT').setText('$' + deal.value.extended_warranty.cost.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            } else {
                form.getTextField('EXTENDED_WARRANTY_AMOUNT').setFontSize(7)
                form.getTextField('EXTENDED_WARRANTY_AMOUNT').setText(toUpperCase('Not Purchased'))
                form.getTextField('EXTENDED_WARRANTY_AMOUNT').updateAppearances(helveticaBoldFont)
            }

            const dealer_name = form.getTextField('DEALER_NAME');
            dealer_name.enableMultiline();
            dealer_name.setText(toUpperCase(dealerStore.dealerData.dealer_settings.legal_name + ' O/A \n' + dealerStore.dealerData.dealer_settings.business_name));
            dealer_name.setFontSize(10)
            dealer_name.updateAppearances(helveticaBoldFont);
            const address = form.getTextField('DEALER_ADDRESS');
            address.setText(toUpperCase(dealerStore.dealerData.dealer_address.street_address) + '\n' + toUpperCase(dealerStore.dealerData.dealer_address.city) + ', ' + toUpperCase(dealerStore.dealerData.dealer_address.postal_code));
            address.updateAppearances(helveticaBoldFont)

            const reg_info = form.getTextField('DEALER_REG_INFO');
            reg_info.setText('MVDA REG. #: ' + dealerStore.dealerData.dealer_settings.registration.dealer_number + ' \nH.S.T #: ' + dealerStore.dealerData.dealer_settings.registration.tax.registration_number);
            reg_info.updateAppearances(helveticaBoldFont)


            // Flatten the form to lock the fields
            form.flatten();

            // Save the PDF and download
            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const urlBlob = URL.createObjectURL(blob);

            // Open the PDF in a new tab
            //window.open(urlBlob, '_blank');


                // Open the PDF in a new tab
                const newTab = window.open(urlBlob, '_blank');
            newTab.onload = function () {
                newTab.print(); // Automatically trigger the print dialog when the PDF loads
            };

        } catch (error) {
            console.log('Error: ' + error)
        }
    }
    const reset_errors = () => {
        errors.value = {
            deal: {
                delivery_date: false,
                deal_date: false,
                deal_status: false,
                salesperson: {
                    name: false
                },
                sales_manager: {
                    name: false
                }
            },
            customer: {
                firstname: false,
                lastname: false,
                phone: false,
                driver_license: false,
                driver_license_expiry: false,
                street_address: false,
                city: false,
                postal_code: false,
                province: false
            },
            vehicle : {
                vin: false,
                year: false,
                make: false,
                model: false,
                odometer: false,
            },
            trade_in: {
                year: false,
                make: false,
                model: false,
                odometer: false,
                trade_in_value: false
            }
        }
    }
    const errors = ref({
        deal: {
            delivery_date: false,
            deal_date: false,
            deal_status: false,
            salesperson: {
                name: false
            },
            sales_manager: {
                name: false
            }
        },
        customer: {
            firstname: false,
            lastname: false,
            phone: false,
            driver_license: false,
            driver_license_expiry: false,
            street_address: false,
            city: false,
            postal_code: false,
            province: false
        },
        vehicle : {
            vin: false,
            year: false,
            make: false,
            model: false,
            odometer: false,
        },
        trade_in: {
            year: false,
            make: false,
            model: false,
            odometer: false,
            trade_in_value: false
        }
    })
    function validateFields() {
        reset_errors();
        const has_errors = ref(false);
        if (customer.value.firstname === '' || customer.value.lastname === '') {
            errors.value.customer.firstname = true;
            errors.value.customer.lastname = true;
            has_errors.value = true;
        }
        if (customer.value.phone === '') {
            errors.value.customer.phone = true;
            has_errors.value = true;
        }
        if (customer.value.driver_license === '') {
            errors.value.customer.driver_license = true;
            has_errors.value = true;
        }
        if (customer.value.driver_license_expiry === '') {
            errors.value.customer.driver_license_expiry = true;
            has_errors.value = true;
        }
        if (customer.value.street_address === '') {
            errors.value.customer.street_address = true;
            has_errors.value = true;
        }
        if (customer.value.city === '') {
            errors.value.customer.city = true;
            has_errors.value = true;
        }
        if (customer.value.province === '') {
            errors.value.customer.province = true;
            has_errors.value = true;
        }
        if (customer.value.postal_code === '') {
            errors.value.customer.postal_code = true;
            has_errors.value = true;
        }
        if (vehicle.value.vin === '' || vehicle.value.vin.length < 16 || vehicle.value.vin.length > 17) {
            errors.value.vehicle.vin = true;
            has_errors.value = true;
        }
        if (vehicle.value.year === '') {
            errors.value.vehicle.year = true;
            has_errors.value = true;
        }
        if (vehicle.value.make === '') {
            errors.value.vehicle.make = true;
            has_errors.value = true;
        }
        if (vehicle.value.model === '') {
            errors.value.vehicle.model = true;
            has_errors.value = true;
        }
        if (vehicle.value.trim === '') {
            errors.value.vehicle.trim = true;
            has_errors.value = true;
        }
        if (vehicle.value.odometer === '') {
            errors.value.vehicle.odometer = true;
            has_errors.value = true;
        }
        if (deal.value.delivery_date === '') {
            errors.value.deal.delivery_date = true;
            has_errors.value = true;
        }
        if (deal.value.deal_date === '') {
            errors.value.deal.deal_date = true;
            has_errors.value = true;
        }
        if (deal.value.deal_status === '') {
            errors.value.deal.deal_status = true;
            has_errors.value = true;
        }
        if (deal.value.sales_manager.name === '') {
            errors.value.deal.sales_manager.name = true;
            has_errors.value = true;
        }
        if (deal.value.salesperson.name === '') {
            errors.value.deal.salesperson.name = true;
            has_errors.value = true;
        }
        if (deal.value.trade_in === true) {
            if (trade_in.value.year === '') {
                errors.value.trade_in.year = true;
                has_errors.value = true;
            }
            if (trade_in.value.make === '') {
                errors.value.trade_in.make = true;
                has_errors.value = true;
            }
            if (trade_in.value.model === '') {
                errors.value.trade_in.model = true;
                has_errors.value = true;
            }
            if (trade_in.value.odometer === '') {
                errors.value.trade_in.odometer = true;
                has_errors.value = true;
            }
            if (trade_in.value.trade_in_value === null) {
                errors.value.trade_in.trade_in_value = true;
                has_errors.value = true;
            }
        }
        return has_errors.value;
    }
    function createDeal() {
        if (validateFields()) {
            return;
        }
        const data = {
            deal_status: deal.value.deal_status,
            vehicle_id: deal.value.vehicle_id,
            customer_id: deal.value.customer_id,
            customer_data: customer.value,
            vehicle_data: vehicle.value,
            tradein_data: trade_in.value,
            deal_data: deal.value,
            pricing_data: pricing_data.value
        }
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }

        axios.post(apiEndpoint + '/' + useDealerStore().dealerData.id + '/deals/', data, configs)
        .then((response) => {
            if (response.status === 200) {
                router.push('/deals')
            }
        })
        .catch((error) => {
            Sentry.captureException(error);
        })
    }
</script>
<template>
    <h3 class="text-3xl font-bold">Create A New Deal</h3>
    <div class="grid gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Deal Information</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-4">
                <div class="col-span-8 grid grid-cols-5 gap-1">
                    <div>
                        <label class="text-xs">Deal Number</label>
                        <h3 class="text-xs">{Auto Generated}</h3>
                    </div>
                    <div class="flex flex-col align-center gap-1">
                        <label class="text-xs">Deal Date</label>
                        <input type="date" v-model="deal.deal_date" class="border rounded px-1" :class="{'border-red-500' : errors.deal.deal_date}"/>
                    </div>
                    <div class="flex flex-col align-center gap-1">
                        <label class="text-xs">Deal Status</label>
                        <select v-model="deal.deal_status" class="border rounded px-1" :class="{'border-red-500' : errors.deal.deal_status}">
                            <option value="Open">Open</option>
                            <option value="Sold">Sold</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Lost">Lost</option>
                        </select>
                    </div>
                    <div class="flex flex-col align-center gap-1">
                        <label class="text-xs">Salesperson</label>
                        <select v-model="deal.salesperson" class="border rounded px-1" :class="{'border-red-500' : errors.deal.salesperson.name}">
                            <option v-for="(salesperson,index) in dealerStore.dealerData.dealer_settings.salespersons" :key="index" :value="salesperson">{{ salesperson.name }}</option>
                        </select>
                        </div>
                    <div class="flex flex-col align-center gap-1">
                        <label class="text-xs">Sales Manager</label>
                        <select v-model="deal.sales_manager" class="border rounded px-1" :class="{'border-red-500' : errors.deal.sales_manager.name}">
                            <option v-for="(salesperson,index) in dealerStore.dealerData.dealer_settings.salespersons"  :key="index" :value="salesperson">{{ salesperson.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-span-4 gap-1 flex justify-end items-center">
                    <button class="bg-gr-green p-2 rounded text-white" v-on:click="fillAndFlattenPdf">Print Bill of Sale</button>
                    <button class="bg-gr-green p-2 rounded text-white" v-on:click="createDeal">Save Deal</button>
                </div>
            </div>
        </div>
    </div>

    <div class="grid xl:grid-cols-2 gap-2 mt-4">
        <!-- First Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Customer Information</div>
            <div class="bg-white grid grid-cols-3 gap-2 px-4 py-4">
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">First Name</label>
                        <input  @keyup.alt.67="YourFn" class="border px-2 rounded" v-model="customer.firstname" :class="{'border-red-500' : errors.customer.firstname}"/>
                        <span v-if="errors.customer.firstname" class="text-xs text-red-500">This field is required</span>
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Middle Name</label>
                        <input class="border px-2 rounded" v-model="customer.middlename" />
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Last Name</label>
                        <input class="border px-2 rounded" v-model="customer.lastname" :class="{'border-red-500' : errors.customer.lastname}"/>
                        <span v-if="errors.customer.lastname" class="text-xs text-red-500">This field is required</span>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-2 gap-2 px-4 py-2">
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Email Address</label>
                        <input type="email" class="border px-2 rounded" v-model="customer.email" />
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Phone Number</label>
                        <input class="border px-2 rounded" v-model="customer.phone" :class="{'border-red-500' : errors.customer.phone}"/>
                        <span v-if="errors.customer.phone" class="text-xs text-red-500">Customer phone number is required.</span>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-5 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Driver License Number</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.driver_license" :class="{'border-red-500' : errors.customer.driver_license}"/>
                        <span v-if="errors.customer.driver_license" class="text-xs text-red-500">Driver License Number is required.</span>
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">License Expiry</label>
                        <input type="date" class="border px-2 rounded" v-model="customer.driver_license_expiry" :class="{'border-red-500' : errors.customer.driver_license_expiry}"/>
                        <span v-if="errors.customer.driver_license_expiry" class="text-xs text-red-500">Expiry date is required.</span>
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Date of Birth</label>
                        <input type="date" class="border px-2 rounded" v-model="customer.date_of_birth" />
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Gender</label>
                        <select class="border px-2 rounded" placeholder="Select" >
                            <option disabled>Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-7 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Street Address</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.street_address" :class="{'border-red-500' : errors.customer.street_address}"/>
                        <span v-if="errors.customer.street_address" class="text-xs text-red-500">Street Address is required.</span>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Street Address 2</label>
                        <input type="text" class="border px-2 rounded" v-model="customer.street_address2" />
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">City</label>
                        <input class="border px-2 rounded" v-model="customer.city" :class="{'border-red-500' : errors.customer.city}"/>
                        <span v-if="errors.customer.city" class="text-xs text-red-500">City is required.</span>
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Province</label>
                        <select class="border px-2 rounded" placeholder="Select" v-model="customer.province" :class="{'border-red-500' : errors.customer.province}">
                            <option value="ON">Ontario</option>
                            <option value="AB">Alberta</option>
                            <option value="BC">British Columbia</option>
                            <option value="NS">Nova Scotia</option>
                            <option value="PE">Prince Edward Island</option>
                            <option value="QC">Quebec</option>
                            <option value="SK">Saskatoon</option>
                        </select>
                        <span v-if="errors.customer.province" class="text-xs text-red-500">Province is required.</span>
                    </div>
                    <div class="grid grid-cols-1 gap-1 flex content-start">
                        <label class="text-xs">Postal Code</label>
                        <input class="border px-2 rounded" v-model="customer.postal_code" :class="{'border-red-500' : errors.customer.postal_code}"/>
                        <span v-if="errors.customer.postal_code" class="text-xs text-red-500">Postal code is required.</span>
                    </div>
            </div>
            <!-- <div class="bg-white flex justify-end gap-2 px-4 py-4">
                <button to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Save</button>
            </div> -->
        </div>

        <!-- Second Section -->
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Vehicle Information</div>
            <div class="bg-white grid grid-cols-3 gap-2 px-4 py-4">
                <div class="col-span-2 grid grid-cols-1 ">
                    <SearchInventory @select-vehicle="selectVehicle"/>
                </div>
                <div>
                    <label class="text-xs">Stock Number</label>
                    <input type="text" class="border px-2 rounded" v-model="vehicle.stock_number" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                    <div class="col-span-3 grid grid-cols-1 gap-1">
                        <label class="text-xs">V.I.N</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.vin" :class="{'border-red-500' : errors.vehicle.vin}"/>
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Year</label>
                        <input class="border px-2 rounded" v-model="vehicle.year" :class="{'border-red-500' : errors.vehicle.year}"/>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Make</label>
                        <select class="border px-2 rounded" placeholder="Select"  v-model="vehicle.make"  :class="{'border-red-500' : errors.vehicle.make}">
                            <option value="" disabled>Select</option>
                            <option v-for="make in makes" :value="make">{{ make }}</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Model</label>
                        <input class="border px-2 rounded" v-model="vehicle.model" :class="{'border-red-500' : errors.vehicle.model}"/>
                    </div>
                    <div class="col-span-1 grid grid-cols-1 gap-1">
                        <label class="text-xs">Trim</label>
                        <input class="border px-2 rounded" v-model="vehicle.trim" :class="{'border-red-500' : errors.vehicle.trim}"/>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Odometer/Mileage</label>
                        <input type="text" class="border px-2 rounded" v-model="vehicle.odometer" :class="{'border-red-500' : errors.vehicle.odometer}"/>
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                        <label class="text-xs">Odometer</label>
                        <div>
                            <div class="flex">
                                <input type="radio" id="radio_odometer_unit_km" class="border w-4 sm:w-3 rounded" value="KM" v-model="vehicle.odometer_unit" /> 
                                <label for="radio_odometer_unit_km" class="text-sm sm:text-xs mx-1">KM</label>
                            </div>
                            <div class="flex">
                                <input type="radio" id="radio_odometer_unit_miles" class="border w-4 sm:w-3 rounded" value="Miles" v-model="vehicle.odometer_unit" /> 
                                <label for="radio_odometer_unit_miles" class="text-sm sm:text-xs mx-1">Miles</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">MTO Brand</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.mto_brand" >
                            <option value="None">None</option>
                            <option value="Unfit">Unfit</option>
                            <option value="Salvage">Salvage</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Exterior Color</label>
                        <input class="border px-2 rounded" v-model="vehicle.exterior_color" />
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Daily Rental?</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.daily_rental">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
                    <div class="col-span-2 grid grid-cols-1 gap-1">
                        <label class="text-xs">Certified?</label>
                        <select class="border px-2 rounded" placeholder="None" v-model="vehicle.certified">
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select>
                    </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Delivery Date</label>
                    <input type="date" class="border px-2 rounded" v-model="deal.delivery_date"  :class="{'border-red-500' : errors.deal.delivery_date}"/>
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Delivery Notes</label>
                    <input type="text" class="border px-2 rounded" v-model="deal.delivery_note" />
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Other Disclosures</label>
                    <div>
                        <div class="flex ">
                            <input type="checkbox" class="border w-3 rounded" v-model="deal.disclosures" /> 
                            <label class="text-xs mx-2">Actual distance travelled may be higher</label>
                        </div>
                    </div>

                </div>
            </div>
            <!-- <div class="bg-white flex justify-end gap-2 px-4 py-4">
                <button to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Save</button>
            </div> -->

        </div>
    </div>

    <!-- Second Container -->
    <div class="grid xl:grid-cols-2 gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top  rounded-t-md px-4 py-4 flex justify-between">
                <div class="text-[rgb(75 85 99 /1)] uppercase text-sm font-medium">Trade-In Information</div>
                <div class="flex gap-1 items-center">
                    <input id="has-trade-in" name="has-trade-in" type="checkbox" v-model="deal.trade_in">
                    <label for="has-trade-in" class="text-xs uppercase">This deal has a trade-in</label>
                </div>
            </div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2" :class="{'blur-[2px]' : !deal.trade_in}">
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">V.I.N</label>
                    <input type="text" class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.vin" />
                </div>
                <div class="grid grid-cols-1 gap-1">
                    <label class="text-xs">Year</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.year" :class="{'border-red-500' : errors.trade_in.year}" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Make</label>
                    <select class="border px-2 rounded disabled:bg-gray-50" placeholder="Select" v-model="trade_in.make" :disabled="!deal.trade_in" :class="{'border-red-500' : errors.trade_in.make}" >
                        <option value="" disabled>Select</option>
                        <option v-for="make in makes" :value="make">{{ make }}</option>
                    </select>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Model</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.model"  :class="{'border-red-500' : errors.trade_in.model}" />
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Trim</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.trim" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2" :class="{'blur-[2px]' : !deal.trade_in}">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Odometer/Mileage</label>
                    <input type="text" class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.odometer"  :class="{'border-red-500' : errors.trade_in.model}" />
                </div>
                <div class="grid grid-cols-1 gap-1">
                    <label class="text-xs">Unit</label>
                    <div>
                        <div class="flex">
                            <input type="checkbox" class="border w-3 rounded" :disabled="!deal.trade_in" value="KM" v-model="trade_in.odometer_unit" /> 
                            <label class="text-xs mx-1">KM</label>
                        </div>
                        <div class="flex">
                            <input type="checkbox" class="border w-3 rounded" :disabled="!deal.trade_in" value="Miles" v-model="trade_in.odometer_unit" /> 
                            <label class="text-xs mx-1">Miles</label>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Exterior Color</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.exterior_color" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Accident?</label>
                    <select class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.accidents" placeholder="None" >
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                        <option value="Not sure">Not sure</option>
                    </select>
                </div>
                <div class="col-span-5 grid grid-cols-1 gap-1">
                    <label class="text-xs">Discosures</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.disclosures" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-6 gap-2 px-4 py-2" :class="{'blur-[2px]' : !deal.trade_in}">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Trade-In Value</label>
                    <input type="number" class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.trade_in_value"  :class="{'border-red-500' : errors.trade_in.trade_in_value}" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Lien Payout</label>
                    <input type="number" class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.lien_payout" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Lien Holder</label>
                    <input class="border px-2 rounded" :disabled="!deal.trade_in" v-model="trade_in.lien_holder" />
                </div>

            </div>
        </div>
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Insurance Information & Extended Warranty</div>
            <div class="bg-white grid grid-cols-9 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Policy #</label>
                    <input type="text" class="border px-2 rounded" v-model="deal.insurance.policy_number" />
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Broker/Company Name</label>
                    <input class="border px-2 rounded" v-model="deal.insurance.company" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Phone Number</label>
                    <input type="phone" class="border px-2 rounded" v-model="deal.insurance.insurer_phone" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Expiry Date</label>
                    <input type="date" class="border px-2 rounded" v-model="deal.insurance.policy_expiry" />
                </div>
            </div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Extended Warranty?</label>
                    <select class="border px-2 rounded" placeholder="None" v-model="deal.extended_warranty.status">
                        <option :value="false">No</option>
                        <option :value="true">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded" v-model="deal.extended_warranty.provider" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" v-model="deal.extended_warranty.term">
                        <option value="3">3</option>
                        <option value="6">6</option>
                        <option value="12">12</option>
                        <option value="18">18</option>
                        <option value="24">24</option>
                        <option value="30">30</option>
                        <option value="36">36</option>
                        <option value="42">42</option>
                        <option value="48">48</option>
                        <option value="60">60</option>
                    </select>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Mileage</label>
                    <input class="border px-2 rounded"  v-model="deal.extended_warranty.mileage"/>
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Price <i class="text-2xs">(before tax)</i></label>
                    <input type="number" class="border px-2 rounded" v-model="deal.extended_warranty.cost" />
                </div>

            </div>
            <!-- <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Gap Insurance?</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">No</option>
                        <option value="None">Yes</option>
                    </select>
                </div>

                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Provider</label>
                    <input type="text" class="border px-2 rounded"/>
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Term (months)</label>
                    <select class="border px-2 rounded" placeholder="None" >
                        <option value="None">3</option>
                        <option value="None">6</option>
                        <option value="None">12</option>
                        <option value="None">18</option>
                        <option value="None">24</option>
                        <option value="None">30</option>
                        <option value="None">36</option>
                        <option value="None">42</option>
                        <option value="None">48</option>
                    </select>
                </div>
                <div class="col-span-3 grid grid-cols-1 gap-1">
                    <label class="text-xs">Warranty Price:  <i class="text-2xs">(before tax)</i></label>
                    <input class="border px-2 rounded"/>
                </div>
            </div> -->
        </div>
    </div>

    <!-- Third Container -->

    <div class="grid grid-cols-2 gap-2 mt-4">
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Pricing Information</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-6 grid grid-cols-1 gap-1">
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Vehicle Price</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" v-model="deal.vehicle_price" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Extended Warranty</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" disabled v-model="deal.extended_warranty.cost" />
                    </div>
                    <!-- <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Gap Insurance</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" v-model="deal.gap_insurance" />
                    </div> -->
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Licensing Fee</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" v-model="deal.licensing_fee" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Gasoline</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" v-model="deal.gasoline" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Deposit</label>
                        <input type="number" v-on:wheel.prevent class="col-span-4 border px-2 rounded" v-model="deal.deposit" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Trade-In</label>
                        <input type="text" disabled class="col-span-4 border px-2 rounded" v-model="trade_in.trade_in_value" />
                    </div>
                    <div class="grid grid-cols-12 items-center">
                        <label class="col-span-6 text-normal">Lien Payout</label>
                        <input type="text" disabled class="col-span-4 border px-2 rounded" v-model="trade_in.lien_payout" />
                    </div>
                    <DealExtraAddons :addons="deal_addons" @add-addon="addAddon" @remove-addon="removeAddon"/>
                </div>
                <div class="col-span-6 grid grid-cols-1 gap-1 content-center">
                    <div class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Vehicle Price</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.vehicle_price }}</p>
                    </div>
                    <div v-if="deal.extended_warranty.status" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Extended Warranty</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.extended_warranty.cost }}</p>
                    </div>
                    <div v-for="addon in deal_addons" :key="addon.price" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">{{addon.label}}</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ addon.price }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Subtotal: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ subtotal }}</p>
                    </div>
                    <div v-if="deal.trade_in && trade_in.trade_in_value > 0" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Trade-In Allowance</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ trade_in.trade_in_value }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Net Difference: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ net_difference }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">HST <span v-if="deal.trade_in_value > 0" class="text-xs font-normal"> (on Net Difference)</span>: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ tax }}</p>
                    </div>
                    <div v-if="deal.licensing_fee > 0" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Licensing Fee</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.licensing_fee }}</p>
                    </div>
                    <div v-if="deal.gasoline > 0" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Gasoline</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ deal.gasoline }}</p>
                    </div>
                    <div v-if="deal.trade_in && trade_in.lien_payout > 0" class="grid grid-cols-12 items-center">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1">Lien Payout</p>
                        <p class="col-span-6 text-normal text-left mr-1 font-semibold">${{ trade_in.lien_payout }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Subtotal: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ subtotal2 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Payable on Delivery: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">${{ subtotal2 }}</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">Deposit: </p>
                        <p class="col-span-6 text-normal text-left mr-1 font-bold">( ${{ deal.deposit }} )</p>
                    </div>
                    <div class="grid grid-cols-12 items-center border-t">
                        <p class="col-span-6 text-normal border-r pr-2 text-right mr-1 font-bold">TOTAL BALANCE DUE: </p>
                        <p class="col-span-6 text-xl text-left mr-1 font-bold">${{ total_balance_due }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="border rounded-t-md">
            <div class="bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">Bill of Sale Comments & Deal Notes</div>
            <div class="bg-white grid grid-cols-12 gap-2 px-4 py-2">
                <div class="col-span-12 grid grid-cols-1 gap-1">
                    <label class="text-xs">Bill of Sale Comments</label>
                    <textarea class="border rounded p-2" v-model="deal.deal_comments"></textarea>
                </div>
                <div class="col-span-12 grid grid-cols-1 gap-1">
                    <label class="text-xs">Deal Notes (Internal notes not visible on Bill of Sale)</label>
                    <textarea class="border rounded p-2" v-model="deal.deal_notes"></textarea>
                </div>
            </div>
        </div>
    </div>

</template>