<script setup>
import { ref } from 'vue';

const emit = defineEmits(['addTransaction', 'removeTransaction'])

const props = defineProps({ 
    transactions: Array
})

const new_transaction = ref({
    date: null,
    type: "",
    subtotal: null,
    tax: null,
    total: null,
    description: ""
})

const errors = ref({
    date: false,
    type: false,
    subtotal: false,
    tax: false,
    total: false,
    description: false
})

function validateFields() {
    errors.value = {
        date: false,
        type: false,
        subtotal: false,
        tax: false,
        total: false,
        description: false
    }
    const control = ref(true);
    if (new_transaction.value.date === null) {
        errors.value.date = true;
        control.value = false;
    }
    if (new_transaction.value.type === "") {
        errors.value.type = true;
        control.value = false;
    }
    if (new_transaction.value.subtotal === null) {
        errors.value.subtotal = true;
        control.value = false;
    }
    if (new_transaction.value.tax === null) {
        errors.value.tax = true;
        control.value = false;
    }
    if (new_transaction.value.total === null) {
        errors.value.total = true;
        control.value = false;
    }

    return control.value;
}
function formatTransactionType(type) {
    const types = {
        "purchase" : 'Purchase',
        "expense" : 'Expense',
        "sale" : 'Sale'
    }
    return types[type];
}

// A function to pass the new transaction variable to the parent component
function addTransaction() {
    if (validateFields()) {
        try {
            emit("addTransaction", new_transaction.value)
            new_transaction.value = {
                date: null,
                type: "",
                subtotal: null,
                tax: null,
                total: null,
                description: ""
            };
        } catch {
            console.log('error')
        }
    }
}
function removeTransaction(index) {
        try {
            emit("removeTransaction", index)
        } catch {
            console.log('error')
        }
}
function formatNumberWithCurrency(number) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
}
</script>
<template>
    <div class="bg-white py-2">
        <div class="releative overflow-hidden">
            <h3 class="px-4">Transcations</h3>
            <div class="border rounded-t-md flex flex-col my-4 mx-2  overflow-x-scroll">
                <div class="grid grid-cols-12 sm:w-full bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4">
                    <div class="col-span-2 sm:col-span-2">Date</div>
                    <div class="col-span-2 sm:col-span-2">Type</div>
                    <div class="col-span-1 sm:col-span-1 sm:block">Subtotal</div>
                    <div class="col-span-1 sm:col-span-1 sm:block">Tax Paid</div>
                    <div class="col-span-1 sm:col-span-1 sm:block">Total</div>
                    <div class="col-span-2 sm:col-span-2 sm:block">Description</div>
                    <div></div>
                </div>
                <div v-for="(transaction, index) in props.transactions">
                    <div  class="grid grid-cols-12 items-center sm:w-full px-4 py-8 sm:py-4 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                        <div class="col-span-3 sm:col-span-2">{{transaction.date}}</div>
                        <div class="col-span-7 sm:col-span-2">{{ formatTransactionType(transaction.type)}}</div>
                        <div class="col-span-1 sm:col-span-1 sm:block">{{formatNumberWithCurrency(transaction.subtotal)}}</div>
                        <div class="col-span-1 sm:col-span-1 sm:block">{{formatNumberWithCurrency(transaction.tax)}}</div>
                        <div class="col-span-1 sm:col-span-1 sm:block">{{formatNumberWithCurrency(transaction.total)}}</div>
                        <div class="col-span-4 sm:col-span-4 sm:block">{{transaction.description}}</div>
                        <div class="col-span-1 sm:col-span-1">
                            <button class="bg-red-600 text-white rounded px-2 py-0.5 text-xs" v-on:click="removeTransaction(index)">Delete</button>
                        </div>
                    </div>
                </div>
                <div v-if="props.transactions.length < 1">
                    <div  class="grid grid-cols-12 items-center w-full px-4 py-8 sm:py-4 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                        <div class="col-span-12 sm:col-span-12 text-center">No transactions have been entered for this vehicle.</div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Add Transcation Section -->
        <div class="py-4 bg-gray-100 m-2 rounded">
            <h3 class="px-4">Add New Transcation</h3>
            <div class="grid sm:grid-cols-7 gap-2 px-4 py-2">
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Transcation Type</label>
                    <select class="border px-2 rounded"  v-model="new_transaction.type" :class="{'border-red-400' : errors.type}">
                        <option value="purchase">Purchase</option>
                        <option value="expense">Expense</option>
                        <option value="sale">Sale</option>
                    </select>
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Transcation Date</label>
                    <input type="date" class="border px-2 rounded" v-model="new_transaction.date" :class="{'border-red-400' : errors.date}" />
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Subtotal</label>
                    <input type="number" class="border px-2 rounded"  v-model="new_transaction.subtotal" :class="{'border-red-400' : errors.subtotal}" />
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Tax Amount</label>
                    <input type="number" class="border px-2 rounded" v-model="new_transaction.tax" :class="{'border-red-400' : errors.tax}" />
                </div>
                <div class="col-span-1 grid grid-cols-1 gap-1">
                    <label class="text-xs">Total Amount</label>
                    <input type="number" class="border px-2 rounded"  v-model="new_transaction.total" :class="{'border-red-400' : errors.total}" />
                </div>
                <div class="col-span-2 grid grid-cols-1 gap-1">
                    <label class="text-xs">Description</label>
                    <input type="text" class="border px-2 rounded"  v-model="new_transaction.description" :class="{'border-red-400' : errors.description}" />
                </div>
            </div>
            <div class="flex justify-end px-4 py-2">
                <button class="bg-gr-green text-white text-sm sm:text-md py-2 px-4 rounded-md" v-on:click="addTransaction()">Add Transcation</button>
            </div>
        </div>
        <!-- End: Add Transcation Section -->

    </div>

</template>