<script setup>
import { ref } from 'vue';
const emit = defineEmits(['addAddon', 'removeAddon'])

const props = defineProps({
    addons: Array
})

const newAddon = ref({
    label: "",
    price: null
})

function addAddon() {
    if (newAddon.value.label != '' && newAddon.value.price !== null) {
        emit('addAddon', {
            label: newAddon.value.label,
            price: newAddon.value.price
        })
        newAddon.value = {
            label: "",
            price: null
        }
        document.getElementById('addon-label').focus()

    }
}
function nextInput(e) {
    console.log(e)
    const inputs = Array.from(e.target.form.querySelectorAll('input[type="number"]'));
      const index = inputs.indexOf(e.target);

      if (index === 0 && index < inputs.length) {
        inputs[index + 1].focus();
      } else {
        inputs[0].focus();
      }

}
function removeAddon(index) {
    emit('removeAddon', index)
}
</script>
<template>
    <div v-for="(addon, index) in props.addons" class="grid grid-cols-12 items-center">
        <label class="col-span-6 text-normal">{{addon.label}}</label>
        <input type="text" class="col-span-4 border px-2 rounded" v-model="addon.price"/>
        <div class="ml-1 col-span-1">
            <button class="text-xs p-1 bg-red-500 rounded text-white" v-on:click="removeAddon(index)">Remove</button>
        </div>
    </div>
    <div class="flex flex-col bg-gray-100 p-2 rounded">
        <div>Add deal addons</div>
        <form class="grid grid-cols-12 items-center gap-1"  v-on:submit.prevent>
            <input type="text" id="addon-label" name="label" class="col-span-6 text-normal border px-2 rounded" :disabled="props.addons.length > 4" v-model="newAddon.label" v-on:keypress.enter="nextInput">
            <div class="col-span-4 flex items-center ">
                <span class="ml-1 absolute text-sm px-0">$</span>
                <input type="number" v-on:wheel.prevent id="price" name="price" class="w-full pl-4 border rounded" :disabled="props.addons.length > 4"  v-model="newAddon.price" v-on:keyup.enter="addAddon" />
            </div>
            <button class="bg-gr-green text-white p-1 rounded col-span-2 disabled:bg-gray-300" :disabled="props.addons.length > 4"  v-on:click="addAddon()">Add</button>
        </form>
    </div>
</template>