import { createApp, watch, ref } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import LoginApp from './LoginApp.vue';
import { useAuthStore } from './stores/auth'

import * as Sentry from "@sentry/vue";


const pinia = createPinia();
const app = createApp(App);
localStorage.theme = 'light'
Sentry.init({
    app,
    dsn: "https://debcfaa1db7d9f08e30295057bf82047@o4508085464399872.ingest.us.sentry.io/4508085467152384",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app.garageregister\.ca/],
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  

app.use(router)
app.use(pinia)

app.use(PrimeVue, {
    
    theme: {
        preset: Aura
    }
});

const authStore = useAuthStore();
authStore.loadAuthInfo();

// watch( , (to, from) => {
//     console.log('statys cgabg')
//     if (to == false) {
//         app.unmount();
//         const loginApp = createApp(LoginApp);
//         loginApp.use(router);
//         loginApp.use(pinia);
//         loginApp.mount('#app');
//         console.log(authStore.getAuthStatus());
//         router.push('/login')
//         }
// })
if ( authStore.getAuthStatus() === true) {
    app.mount('#app')
} else {
    const loginApp = createApp(LoginApp);
    loginApp.use(router);
    loginApp.use(pinia);
    loginApp.mount('#app');
    console.log(authStore.getAuthStatus());
    router.push('/login')
}

authStore.$subscribe((mutation, state) => {
    if (state.authStatus === false) {
        app.unmount();
        const loginApp = createApp(LoginApp);
        loginApp.use(router);
        loginApp.use(pinia);
        loginApp.mount('#app');
        console.log(authStore.getAuthStatus());
        router.push('/login')
    }
})
