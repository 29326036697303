<script setup>
import { onMounted } from 'vue';
import { useDealStore } from '../stores/deals';
import { useDealerStore } from '../stores/dealer';
import { useAuthStore } from '../stores/auth';
const authStore = useAuthStore();
const dealStore = useDealStore();
const dealerStore = useDealerStore();


const convertToFloat = (str) => {
    let num = parseFloat(str);
    if (isNaN(num)) {
        return 0;
    }
    // Round to 2 decimal places
    num = Math.round(num * 100) / 100;
    
    // Add commas to the number
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

}

onMounted(() => {
    if (dealerStore.dealerData?.id) {
        dealStore.fetchDealsSummary();
    }
})
const deals = [
    {
        deal_number: 24921,
        customer_name: 'Yousif Bodagh',
        deal_status: 'Active',
        vehicle: '2021 Audi Q5',
        stock_number: 'P0231',
        total_deal: 123321
    },
    {
        deal_number: 24919,
        customer_name: 'Ledia Aesar',
        deal_status: 'Pending',
        vehicle: '2018 BMW M4',
        stock_number: 'P0227',
        total_deal: 123321
    },
    {
        deal_number: 24918,
        customer_name: 'Karlos Bodagh',
        deal_status: 'Delivered',
        vehicle: '2021 Audi Q5',
        stock_number: 'P0231',
        total_deal: 123321
    },
    {
        deal_number: 24917,
        customer_name: 'Reta Bodagh',
        deal_status: 'Delivered',
        vehicle: '2021 Audi Q5',
        stock_number: 'P0231',
        total_deal: 123321
    },
]
</script>

<template>
    <h3 class="text-3xl font-bold">Deals</h3>

    <div v-if="!dealerStore.dealerData.dealer_settings.plan.features.deals" class="grid border border-gr-green rounded-lg p-4 text-center my-8 bg-white gap-2 shadow">
        <div class="text-xl font-semibold">Deals Not Available</div>
        <div>This feature is not enabled for your dealership. Please contact sales.</div>
        <div class="my-4">
            <a href="https://garageregister.ca/sales/" target="_blank" class="bg-gr-green px-4 py-2 rounded-md text-white text-base">Contact Sales</a>
        </div>
    </div>
    <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals" class="hidden grid grid-cols-8 gap-3 my-4">
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Pending Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Sold Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Draft Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">14</span></div>
            <div class="text-sm font-medium">Delivered Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$51,250</span></div>
            <div class="text-sm font-medium">Revenue in Pending Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$33,500</span></div>
            <div class="text-sm font-medium">Revenue in Sold Deals</div>
        </div>
        <div class="border rounded-md bg-gr-grid-top border-slate-200 px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-gray-700">$71,180</span></div>
            <div class="text-sm font-medium">Revenue in Delivered Deals</div>
        </div>
        <div class="border rounded-md bg-gr-green border-gr-green text-white px-4 py-2 text-center space-y-1">
            <div><span class="text-3xl font-bold text-white">$11,250</span></div>
            <div class="text-sm font-medium">Profit in Delivered Deals</div>
        </div>
    </div>

    <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals" class="my-2 flex flex-row justify-end	items-stretch">
        <div class="flex px-2 items-stretch">
            <input type="text" class="rounded px-2 w-72 text-xs" placeholder="Search by Deal #, Customer Name, Stock # ...">
        </div>
        <router-link to="/deals/create" class="bg-gr-green px-4 py-2 rounded-md text-white text-sm">Create A Deal</router-link>
    </div>
    <div v-if="dealerStore.dealerData.dealer_settings.plan.features.deals" class="border rounded-t-md flex flex-col ">
        <div class=" grid-cols-12 w-full bg-gr-grid-top text-[rgb(75 85 99 /1)] uppercase text-sm font-medium rounded-t-md px-4 py-4 hidden md:grid">
            <div>Deal #</div>
            <div class="col-span-3">Customer Name</div>
            <div class="col-span-2">Deal Status</div>
            <div class="col-span-2">Vehicle</div>
            <div class="col-span-2">Stock #</div>
            <div>Total Sale</div>
            <div></div>
        </div>
        <div v-for="deal in dealStore.deals" class="md:hidden">
            <div class="grid grid-cols-10 gap-2 items-center w-full px-4 py-3 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div class="col-span-7 font-bold">{{deal.customer_firstname}} {{deal.customer_lastname}}</div>
                <div class="col-span-3 flex justify-items-stretch justify-stretch">
                    <div v-if="deal.deal_status === 'open'" class="bg-[#2196F3] text-white py-1 px-3 rounded w-full text-center text-xs">Open</div>
                    <div v-if="deal.deal_status === 'sold'" class="bg-[#4CAF50] text-white py-1 px-3 rounded w-full text-center text-xs">Sold</div>
                    <div v-if="deal.deal_status === 'delivered'" class="bg-[#015e54] text-white py-1 px-3 rounded w-full text-center text-xs">Delivered</div>
                    <div v-if="deal.deal_status === 'lost'" class="bg-[#BDBDBD] text-black py-1 px-3 rounded w-full text-center text-xs">Lost</div>
                </div>
                <div class="col-span-7 font-semibold">{{deal.vehicle_info}}</div>
                <div class="col-span-3 flex justify-end">Stk #: {{deal.vehicle_stock_no}}</div>

                <div class="col-span-4">Deal #: {{deal.id}}</div>
                <div class="col-span-3 font-semibold">${{ convertToFloat(deal.total_price) }}</div>
                <div class="col-span-3 items-center flex justify-end">
                    <router-link :to="'/deals/' + deal.deal_id + '/edit'" class="border px-2 py-1.5 w-full text-center rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">View Deal</router-link></div>
            </div>
        </div>
        <div v-for="deal in dealStore.deals" class="hidden md:block">
            <div class="grid grid-cols-12 items-center w-full px-4 py-3 border-t text-sm bg-white border-l-gr-green border-l-2 cursor-pointer hover:bg-gr-grid-top">
                <div>{{deal.deal_id}}</div>
                <div class="col-span-3">{{deal.customer_firstname}} {{deal.customer_lastname}}</div>
                <div class="col-span-2 flex">
                    <div v-if="deal.deal_status === 'open'" class="bg-[#2196F3] text-white py-1 px-3 rounded w-24 text-center">Open</div>
                    <div v-if="deal.deal_status === 'sold'" class="bg-[#4CAF50] text-white py-1 px-3 rounded w-24 text-center">Sold</div>
                    <div v-if="deal.deal_status === 'delivered'" class="bg-[#015e54] text-white py-1 px-3 rounded w-24 text-center">Delivered</div>
                    <div v-if="deal.deal_status === 'lost'" class="bg-[#BDBDBD] text-black py-1 px-3 rounded w-24 text-center">Lost</div>
                </div>
                <div class="col-span-2">{{deal.vehicle_info}}</div>
                <div class="col-span-2">{{deal.vehicle_stock_no}}</div>
                <div>${{ deal.total_price }}</div>
                <div><router-link :to="'/deals/' + deal.deal_id + '/edit'" class="border px-2 py-1.5 rounded-md border-gray-300 hover:bg-gr-green hover:text-white hover:border-gr-green">View Deal</router-link></div>
            </div>
        </div>

    </div>
</template>