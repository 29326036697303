<script setup>
import Quagga from 'quagga';
import { ref, computed } from 'vue';

const vin = ref("");
const vin2 = ref("");
function start() {
    Quagga.init({
    inputStream : {
      name : "Live",
      type : "LiveStream",
      target: document.querySelector('#scanner')    // Or '#yourElement' (optional)
    },
    decoder : {
      readers : ["code_39_vin_reader" ]
    }
  }, function(err) {
      if (err) {
        vin.value = err;
          return
      }
      console.log("Initialization finished. Ready to start");
      Quagga.start();
  });

  Quagga.onDetected(showVin);
  Quagga.onProcessed(showVin2);

  function showVin(data) {
    vin.value = data.codeResult.code
  }
  function showVin2(data) {
    vin2.value = data.codeResult.code
  }

}

</script>

<template>
    <h1>Scanner</h1>
    <h1>{{ vin }}</h1>
    <h1>{{ vin2 }}</h1>

    <button class="bg-red-100 p-8" v-on:click="start()">Start</button>
    <div id="scanner">

    </div>
</template>