'use strict';
import {useAuthStore} from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import axios from 'axios';

const decodeVin = async (vin)=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        return axios.get(apiEndpoint + '/dealer/' + dealerStore.getDealerData().id + "/tools/vin-decode/" + vin,configs)
        .then(response => response.data)
        .catch((error) => {
            return error.response;
        })
    }
}

export { decodeVin}