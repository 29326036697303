<script setup>
import NavigrationTabs from '../../components/Settings/NavigationTabs.vue'
import {ref, onMounted, watch} from 'vue';
import axios from 'axios';
import { useAuthStore } from '../../stores/auth.js'
import { useDealerStore } from '../../stores/dealer.js'
import SalesTeamEditPopup from '../../components/Settings/SalesTeamEditPopup.vue';

const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const authStore = useAuthStore();
const dealerStore = useDealerStore();
const salespersons = ref([]);
const addSalespersonPopupStatus = ref(false);

const new_salesperson_errors = ref({
    name: false,
    role: false,
    registration_no: false
})
const new_salesperson = ref({
    name: "",
    role: "",
    registration_no: "",
    registration_expiry: ""
})

const editPopup = ref({
    salesperson: null,
    status: false,
    success: false,
    error: false
})
onMounted(() => {
    getSalespersons();
})
function getSalespersons() {
    if (useAuthStore().getAuthToken() != '' && dealerStore.dealerData.id !== null) {
        const configs = {
            headers: {
                "x-auth-token" : useAuthStore().getAuthToken()
            }
        }

        axios.get(apiEndpoint + '/dealer/' + useDealerStore().dealerData.id +'/salespersons', configs)
        .then((response) => {
            if (response.status === 200) {
                salespersons.value = response.data.data;
            }
        })
    }
}

function addSalesperson() {
    if (!validateNewSalespersonFields()) {
        const configs = {
            headers: {
                "x-auth-token" : useAuthStore().getAuthToken()
            }
        }

        axios.post(apiEndpoint + '/dealer/' + useDealerStore().dealerData.id +'/salespersons', new_salesperson.value, configs)
        .then((response) => {
            if (response.status === 200) {
                addSalespersonPopupStatus.value = false;
                getSalespersons();
            }
        })
        .catch((error) => {

        })
    }

}

function updateSalesperson(data) {
        const configs = {
            headers: {
                "x-auth-token" : useAuthStore().getAuthToken()
            }
        }
        const data_updated = {
            name: data.name,
            role: data.role,
            registration_no: data.registration_no,
            registration_expiry: data.registration_expiry,
            status: data.status,
            s_id: data.id
        }
        axios.post(apiEndpoint + '/dealer/' + useDealerStore().dealerData.id +'/salespersons/' + data.id, data_updated, configs)
        .then((response) => {
            if (response.status === 200) {
                getSalespersons();
                editPopup.value.success = true;
                setTimeout(()=> {
                    editPopup.value.salesperson = null,
                    editPopup.value.success = false,
                    editPopup.value.status = false;
                }, 1000)
            }
        })
        .catch((error) => {
            editPopup.value.error = true;
        })

    

}

function validateNewSalespersonFields() {
    const errors = ref(false);
    if (new_salesperson.value.name === '') {
        new_salesperson_errors.value.name = true;
        errors.value = true;
    } else {
        new_salesperson_errors.value.name = false;
    }
    if (new_salesperson.value.role === '') {
        new_salesperson_errors.value.role = true;
        errors.value = true;
    } else {
        new_salesperson_errors.value.role = false;
    }

    return errors.value
}
function closeEditPopup() {
    editPopup.value.status = false;
}
function editSalesperson(salesperson) {
    const a = { ...salesperson };
    editPopup.value.salesperson = { ...salesperson };
    editPopup.value.status = true;
}
// Watch for changes in the token and dealer data
watch(
  [() => authStore.getAuthToken(), () => dealerStore.dealerData?.id],
  ([newToken, newDealerId]) => {
    if (newToken && newDealerId != null) {
      getSalespersons();
    }
  }
);

</script>
<template>
    <h3 class="text-3xl font-bold">Dealership Settings</h3>
    <div class="grid mt-4">
        <NavigrationTabs />
        <div class="border rounded-t-md">
            <div class="grid grid-cols-1 bg-white">
                <div class="bg-white flex justify-between gap-2 px-4 py-4 items-center">
                    <div class="text-sm">To add a new sales staff, click on <strong>+ Add Sales Team Member</strong>. Please note that sales team members cannot be deleted but they can be deactivated.</div>
                    <button class="bg-gr-green p-2 rounded text-white" v-on:click="addSalespersonPopupStatus = true"><i class="fa-solid fa-user-plus"></i> Add Sales Team Member</button>
                </div>
                <div class="rounded border mx-4 mb-8">
                    <div class="grid grid-cols-10 bg-gray-100 font-semibold">
                        <div class="col-span-2 p-2">Name</div>
                        <div class="col-span-2 p-2">Role</div>
                        <div class="col-span-2 p-2">Registration No.</div>
                        <div class="col-span-2 p-2">License Expiry Date</div>
                        <div class="p-2">Status</div>
                        <div class="p-2"></div>
                    </div>
                    <div v-for="salesperson in salespersons" :key="salesperson.id" class="grid grid-cols-10 border-b">
                        <div class="col-span-2 p-2">{{salesperson.name}}</div>
                        <div class="col-span-2 p-2">{{salesperson.role}}</div>
                        <div class="col-span-2 p-2">{{salesperson.registration_no}}</div>
                        <div class="col-span-2 p-2">{{salesperson.registration_expiry}}</div>
                        <div class="p-2">{{salesperson.status}}</div>
                        <div class="p-2 flex justify-end">
                            <button class="bg-gr-green text-white py-1 px-3 rounded mr-2" v-on:click="editSalesperson(salesperson)">Edit</button>
                        </div>
                    </div>
                    <div v-if="salespersons.length === 0" class="col-span-5 p-2">
                        No sales team members found.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Transition>
        <div v-if="addSalespersonPopupStatus" class="w-screen h-screen bg-black bg-opacity-60 fixed left-0 top-0 flex justify-center items-center">
            <div class="relative bg-white rounded py-8 px-6 flex flex-col gap-2 mx-2">
                <div class="absolute right-4 top-4"><i  v-on:click="addSalespersonPopupStatus = false" class="p-1.5 bg-gray-300 hover:bg-gray-400 cursor-pointer rounded fa-solid fa-xmark"></i></div>
                <h4 class="text-[24px] font-semibold"><i class="text-gr-green fa-solid fa-user-plus"></i> Add New Member</h4>
                <p>Enter the information below:</p>
                <div class="grid grid-cols-2 gap-2 my-2">
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Full Name: </label>
                        <input type="text" class="rounded border" v-model="new_salesperson.name" :class="{'border-red-500' : new_salesperson_errors.name}"/>
                        <span v-if="new_salesperson_errors.name" class="text-xs text-red-600">This field is required.</span>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Role: </label>
                        <select class="rounded border"  v-model="new_salesperson.role"  :class="{'border-red-500' : new_salesperson_errors.role}">
                            <option value="Salesperson">Salesperson</option>
                            <option value="Sales Manager">Sales Manager</option>
                            <option value="General Sales Manager">General Sales Manager</option>
                            <option value="Finance Manager">Finance Manager</option>
                        </select>
                        <span v-if="new_salesperson_errors.role" class="text-xs text-red-600">This field is required.</span>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Registration No.: </label>
                        <input type="number" class="rounded border" v-model="new_salesperson.registration_no"/>
                    </div>
                    <div class="flex flex-col gap-1">
                        <label class="text-sm">Registration Expiry Date: </label>
                        <input type="date" class="rounded border" v-model="new_salesperson.registration_expiry"/>
                    </div>
                </div>
                <div class="flex mt-4 gap-4">
                    <button class="bg-gr-green rounded text-white text-lg py-1.5 px-6" v-on:click="addSalesperson()">Add</button>
                    <button class="bg-red-500 rounded text-white text-lg py-1.5 px-6" v-on:click="addSalespersonPopupStatus = false">Close</button>
                </div>
            </div>
        </div>
    </Transition>
    <SalesTeamEditPopup :salesperson="editPopup.salesperson" :edit-salesperson-popup-status="editPopup.status" :success="editPopup.success" :error="editPopup.error" @close-popup="closeEditPopup" @process-member-edit="updateSalesperson" />
    </template>
    <style>
    .v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

    </style>