<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";

const updateNoticeStatus = ref(false);
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
const appCurrentVersion = import.meta.env.VITE_APP_VERSION;

onMounted(() => {
    checkForUpdates()
})

function checkForUpdates() {
    const lastChecked = localStorage.getItem('app-update-last-check');
    if (lastChecked == null) {
        getAppLastVersion();
    } else {
        if ( parseInt(lastChecked) + 600000 < Date.now() ) {
            getAppLastVersion();
        } else {
            checkAppVersion();
        }
    }
}

function getAppLastVersion() {
    axios.get(apiEndpoint + '/system/version')
    .then((response) => {
        if (response.status === 200) {
            if (response.data.data.app_frontend_version > appCurrentVersion) {
                localStorage.setItem('app-version', response.data.data.app_frontend_version);
                localStorage.setItem('app-update-last-check', Date.now())
                checkAppVersion()
            }
        }
    })
    .catch((error) => {
        console.log('Could not fetch app version');
    })
}

function checkAppVersion() {
    if (localStorage.getItem('app-version') !== appCurrentVersion) {
        updateNoticeStatus.value = true;
    }
}

function fetchNewVersion() {
    if (navigator.serviceWorker && localStorage.getItem('app-refresh') === null) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => registration.unregister());
      // Reload after unregistering service worker
      window.location.reload();
      localStorage.setItem('app-refresh', '1');
    });
  } else {
    window.location.href = window.location.origin + window.location.pathname + '?t=' + new Date().getTime();
}

}

</script>
<template>
    <div class="flex flex-col mt-36 text-center bg-white py-2 text-xs justify-center">
        <div>Garage Register powered by DealerSwift</div>
        <div>Version {{appCurrentVersion}}</div>
    </div>
    <div v-if="updateNoticeStatus" v-on:click="fetchNewVersion()" class="fixed bottom-0 bg-white m-4 p-3 rounded-xl shadow border border-2 hover:cursor-pointer hover:border-gr-green">
        <div class="text-sm font-semibold">A New Update is Available</div>
        <div class="text-xs">Click here to refresh</div>
    </div>
</template>