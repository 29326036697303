<script setup>

import { ref } from 'vue';

const props = defineProps({
    images: Array
})

const currentSlide = ref(0);
const setActiveSlide = (index) => {
    currentSlide.value = index;
}
</script>

<template>
    <div v-if="images.length > 0" class="flex flex-col items-center  sm:max-w-full sm:w-full">
        <!-- Main Slider -->
        <div class=" mb-4">
        <img :src="images[currentSlide].link" alt="Main Slide" class="w-full aspect-[4/3] object-contain rounded-lg shadow-lg" />
        </div>

        <!-- Thumbnail Slider -->
        <div class="w-full overflow-x-auto overflow-y-hidden py-2 px-1">
            <div class="flex space-x-2 w-max">
                <div
                v-for="(image, index) in images"
                :key="index"
                class="relative w-32 h-24 cursor-pointer transition-transform duration-200 transform hover:scale-105 flex-shrink-0"
                @click="setActiveSlide(index)"
                >
                <img :src="image.link + '?width=150'" alt="Thumbnail" class="w-full h-full object-cover rounded-md" :class="{ 'border-4 border-gr-green rounded-md': currentSlide === index }"
                />
                </div>
            </div>
        </div>

    </div>
</template>