import { defineStore } from "pinia";
import { ref } from "vue";
import { useDealerStore } from './dealer'

export const useFileDropBoxStore = defineStore('filedropbox', () => {

    const files = ref([]);
    const filesInput = ref([]);
    const uploadedFiles = ref([]);
    const vehicleId = ref('100001');
    const dealerNameRaw = ref('HiWay Auto');

    const uploadFile = () => {

    }

    const dealerId = () => {
        const dealerStore = useDealerStore();
        return dealerStore.getDealerId();

    }
    const dealerName = () => {
        const dealerStore = useDealerStore();
        console.log('Dealer ID is: ' + dealerStore.getDealerId())
        return dealerStore.getDealerId();
    }
    const setVehicleId = (id) => {
        vehicleId.value = id;
    }

    const addToList = (image_object) => {
        uploadedFiles.value.push(image_object);
    }

    return {
        files,
        uploadedFiles,
        uploadFile,
        dealerId,
        vehicleId,
        dealerName,
        setVehicleId,
        addToList
    }
})