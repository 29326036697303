import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { useAuthStore } from "./auth";
import { useDealerStore } from "./dealer";

export const useDealStore = defineStore('dealStore', () => {

    const deals = ref([]);
    const page = ref([]);
    const dealsCount = ref([]);
    const filteredDeals = ref([]);
    const authStore = useAuthStore();
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const dealerStore = useDealerStore();


    const fetchDealsSummary = () => {
        if (dealerStore.dealerData.id != null) {
            const configs = {
                headers: {
                    "x-auth-token" : authStore.getAuthToken()
                }
            }
    
            axios.get( apiEndpoint + '/' + dealerStore.dealerData.id +'/deals/summary', configs)
            .then((response) => {
                deals.value = response.data.data.deals;
            })
    
        }
    }

    return {
        deals,
        page,
        dealsCount,
        fetchDealsSummary
    }
})