'use strict';
import { useAuthStore } from '../stores/auth';
import { useDealerStore } from '../stores/dealer';
import axios from 'axios';
import { useVehiclesStore } from '../stores/vehicles';
import { useSystemStore } from '../stores/system';
import { watch } from 'vue';

const editDeal = async (file, dealer, data)=> {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

    const authStore = useAuthStore();
    const dealerStore = useDealerStore();
    if (authStore.authStatus && dealerStore.getDealerData().id) {
        const configs = {
            headers: {
                "x-auth-token" : authStore.getAuthToken()
            }
        }
        return axios.get(apiEndpoint + '/dealer/' + dealerStore.getDealerData().id + "/tools/vin-decode/" + vin,configs)
        .then(response => response.data)
        .catch((error) => {

        })
    }
}

const getDeal = async (deal_id) => {
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const authStore = useAuthStore();
    const dealerStore = useDealerStore();

    // Return a Promise that resolves when both authStatus and dealerData.id are valid
    return new Promise((resolve, reject) => {
        // Watch for changes in both authStatus and dealerData.id
        const stopWatch = watch(
        () => [authStore.authStatus, dealerStore.getDealerData().id], // Watch both values
        async ([newAuthStatus, newDealerId]) => {
            // Proceed only if both authStatus is true and dealer id is available
            if (newAuthStatus && newDealerId) {
            try {
                const configs = {
                headers: {
                    "x-auth-token": authStore.getAuthToken(),
                },
                };

                // Await the axios response
                const response = await axios.get(
                `${apiEndpoint}/${newDealerId}/deals/${deal_id}`,
                configs
                );

                stopWatch(); // Stop watching after we have a successful response
                resolve(response); // Resolve the Promise with the response data
            } catch (error) {
                console.error("Error fetching deal data:", error);
                stopWatch(); // Stop watching if there's an error
                reject(error.response || { status: 500, data: "Internal Server Error" }); // Reject the Promise with the error
            }
            }
        },
        { immediate: true } // This ensures the watcher runs immediately if conditions are already met
        );
    });
    
};




export { getDeal, editDeal }