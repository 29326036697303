import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";
import { useDealerStore } from "./dealer";
import * as Sentry from "@sentry/browser";

export const useAuthStore = defineStore('authStore', () => {

    const authStatus = ref(false);
    const authToken = ref('ss');
    const userData = ref({
        firstname: '',
        lastname: '',
        dealerships: []
    });
    const accountStatus = ref(false);
    const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;
    const frontEndURL = import.meta.env.VITE_FRONT_END_URL;
    const errorStatus = ref(false);
    const errorMessage = ref('');
    const loginStatus = ref(false);
    const dealerData = ref({
        dealerId: '10001',
        dealerNumber: '',
        dealerName: 'HiWay Auto Sales',
        dealerAddress: '123 Street Name',
        dealerCity: 'Mississauga',
        dealerState: 'ON',
        dealerZip: 'L7M 4R6',
        dealerCountry: 'CAD',
        dealerStatus: 'active'
    });
    const dealerLimits = ref({
        activeVehiclesLimit: 15,
        currentVehicleUsage: 10,
        vinDecoderLimit: 25,
        currentVinDecoderUsage: 10,
        activeDealsLimit: 15,
        currentDealsUsage: 5
    });

    const loadAuth =() => {
        if (localStorage.getItem('auth_token')) {
            setAuthToken(localStorage.getItem('auth_token'));
            authStatus.value = true;
        }
    }
    const getAuthStatus = () => {
        return authStatus.value;
    }

    const getAuthToken = () => {
        return authToken.value;
    }

    const getUserData = () => {
        return userData.value;
    }

    const getDealerData = () => {
        return dealerData.value;
    }

    const getDealerLimits = () => {
        return dealerLimits.value;
    }

    const setAuthToken = (token) => {
        localStorage.setItem('auth_token', token);
    }                  
    const login = (email, password) => {
        authStatus.value = true;
        errorStatus.value = false;
        errorMessage.value = '';
        loginStatus.value = true;

        if (email !== '' && password !== '') {
            var username = 'scrapbook';
            var authpassword = 'present';
            var basicAuth = 'Basic ' + btoa(username + ':' + authpassword);

            axios.post(apiEndpoint + '/auth/login', {email: email, password: password})
            .then((response) => {
                console.log('test')
                if (response.status === 200) {
                    setAuthToken(response.data.auth_token);
                    //userData.value = response.data.user_data;
                    window.location.href = frontEndURL;
                }  
            })
            .catch((error) => {
                loginStatus.value = false;
                console.log(error)

                if (error) {
                    if (error.response.status == 400) {
                        errorStatus.value = true;
                        errorMessage.value = error.response.data.message;
                    } else if (error.response.status == 401) {
                        errorStatus.value = true;
                        errorMessage.value = error.response.data.message;
                    } else {
                        errorStatus.value = true;
                        errorMessage.value = 'We encountered an unknown error. Please try again later.';
    
                    }
                }

            })
        }
    }

    const verify = () => {
        authStatus.value = true;
        console.log('Auth Status ' + authStatus.value)
    }

    const loadAuthInfo = () => {
        if (localStorage.getItem('auth_token')) {
            authToken.value = localStorage.getItem('auth_token');
            verify();
            fetchUserData();
        }
    }

    const fetchUserData = () => {
        if (authStatus.value && authToken.value) {
            const configs = {
                headers: {
                    'x-auth-token' : authToken.value
                }
            }
            axios.get(apiEndpoint + '/account', configs)
            .then((response) => {
                if (response.status === 200) {
                    userData.value.firstname = response.data.data.user_information.firstname;
                    userData.value.lastname = response.data.data.user_information.lastname;
                    userData.value.dealerships = response.data.data.dealerships;
                    Sentry.setUser({ email: response.data.data.user_information.email_address });

                    if (userData.value.dealerships.length > 0) {
                        const dealerStore = useDealerStore();
                        dealerStore.fetchDealerData(userData.value.dealerships[0].id);
                        accountStatus.value = true;
                    }
                }
            })
            .catch((error) => {
                console.log('error fetching user info')
                authStatus.value = false;
                console.log('error auth')

            })
        }
    }
    const dealerDataRequest = (dealerId) => {
        axios.get('' + dealerId)
        .then((response) => {
            if (response.status === 200) {
                this.dealerData.value = response.data.dealer_data;
                this.dealerLimits.value = response.data.dealer_limits;
            }
        })
        .catch((error) => {

        })
    }

    const logOut = () => {
        localStorage.removeItem('auth_token');
        authStatus.value = false;
        window.location.href = frontEndURL;

    }

    return {
        login,
        verify,
        authStatus,
        errorMessage,
        errorStatus,
        getAuthStatus,
        getAuthToken,
        getDealerData,
        getDealerLimits,
        getUserData,
        loadAuthInfo,
        loginStatus,
        logOut,
        accountStatus,
        userData
    }
})